import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import MainButton from "../../MainButton";

const ImageTextSideTitle = ({ block }) => {
  const image = getImage(block.image);
  return (
    <div className="grid lg:grid-cols-18 relative z-20 py-20 lg:py-24 max-w-screen-xl mx-auto">
      <div className="lg:col-start-2 lg:col-end-7">
        <h2 className="font-display uppercase text-white text-5xl mb-10 whitespace-pre-line">
          {block.title}
        </h2>
        <p className="font-body text-white mb-10">{block.body}</p>
        {block.ctatext ? (
          <div className="w-44">
            <MainButton text={block.ctatext} />
          </div>
        ) : null}
      </div>
      <div className="lg:col-start-8 lg:col-end-14">
        <GatsbyImage image={image} alt={block.image.alt ? block.image.alt : ''} />
      </div>
      <div className="lg:col-start-16 lg:col-end-19 relative">
        <h2 className="text-darks1 font-display uppercase text-7xl whitespace-pre-line lg:transform lg:rotate-90 lg:absolute lg:top-[2rem] lg:right-0 lg:left-0 ">{block.rotatedTitle}</h2>
      </div>
    </div>
  );
};
export default ImageTextSideTitle;
