import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { BsChevronDown } from "react-icons/bs";

import { chunkArray } from '../../../utils/chunkArray';

import FaqAccordians from '../../FaqAccordians';
import YellowButton from '../../Buttons/YellowButton';
import { vertResolver } from '../../../utils/vertResolver';

const FaqBlock = ({ block, locale }) => {
    const { heading, subtitle } = block;
    const [dropdown, setDropdown] = useState(false);
    const [filter, setFilter] = useState('All');
    const [faqsArr, setFaqsArr] = useState(8);
    const [categories, setCategories] = useState(['All']);
    const [faqOpen, setFaqOpen] = useState();

    const { allDatoCmsFaq } = useStaticQuery(graphql`
    query Faqs {
        allDatoCmsFaq {
            edges {
                node {
                    faqTitle
                    body
                    category
                    locale
                }
            }
        }
    }`);

    const localeFaqs = allDatoCmsFaq.edges.filter(({ node: { locale: _locale } }) => _locale === locale);

    const faqs = localeFaqs.map((edge) => {
        const { node } = edge;
        const category = JSON.parse(node.category);

        category && category?.map((cat) => {
            if (!categories.includes(cat)) {
                return setCategories([...categories, cat]);
            }
            return null;
        });
        return node;
    });

    const handleFaq = (index) => {
        setFaqOpen(index);
    }

    const renderFaqs = () => {
        let filtered, arrayChunks;
        const newArr = faqs; // Flatten the faqs array

        if (filter) {
            filtered = faqs.filter((faq) => {
                return JSON.parse(faq?.category).includes(filter);
            });
        }

        arrayChunks = filtered?.length ? chunkArray(filtered.slice(0, faqsArr), 4) : chunkArray(newArr.slice(0, faqsArr), 4);


        return arrayChunks.map((chunk) => {
            return chunk.map((faq, index) => <FaqAccordians open={() => handleFaq(index)} key={index} index={index} faqOpen={faqOpen} title={faq.faqTitle} body={faq.body} />)
        });
    }

    const moreFaqs = () => {
        setFaqsArr(faqsArr + 4);
    };

    const handleFilter = (event) => {
        setFilter(event.target.dataset.name);
        setDropdown(false);
    }

    const handleDropdown = () => {
        setDropdown(!dropdown);
    }

    return (
        <div className="py-20 pt-60 lg:pb-32">
            <div className="grid lg:grid-cols-18 gap-4 max-w-screen-2xl mx-auto w-11/12 lg:w-full">
                <div className="lg:col-start-4 lg:col-end-18 lg:row-start-1">
                    <div className="lg:flex lg:flex-row lg:flex-nowrap lg:items-center lg:justify-between">
                        <h2 className="font-display text-3xl md:text-4xl lg:text-6xl text-darks3 uppercase mb-4 lg:mb-0">{subtitle}</h2>

                        <div className="text-darks3 w-72 cursor-pointer">
                            <div
                                className={`${dropdown ? "bg-offwhite" : "bg-white"} p-2 pl-8 flex flex-row flex-nowrap items-center justify-between`}
                                onClick={handleDropdown}
                                onKeyPress={(e) => (e.key === "Enter") && handleDropdown()}
                                role="tablist"
                                tabIndex={0}
                            >
                                {filter}
                                <div className={`${dropdown ? "bg-darks3" : "bg-offwhite"} p-4`}>
                                    <BsChevronDown className={`${dropdown ? 'rotate-180 text-blue' : 'rotate-0 text-darks5'}  transition-all`} />
                                </div>
                            </div>
                            <div className={`${dropdown ? "flex" : "hidden"} flex-col absolute w-72`}>
                                {categories && categories.map((cat, index) => (
                                    <div key={index} className="p-4 bg-darks3 w-full text-gray hover:bg-offwhite hover:text-darks3 transition-all cursor-pointer"
                                        data-name={cat}
                                        onClick={handleFilter}
                                        onKeyPress={(e) => (e.key === "Enter") && handleDropdown()}
                                        role="tab"
                                        tabIndex={0}
                                    >
                                        {cat}
                                    </div>
                                )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-6 lg:col-start-2 lg:col-end-3 lg:row-start-2">
                    {vertResolver(locale) ? <div className="flex flex-row lg:block">{heading.split('').map(letter => <h1 className="text-darks3 uppercase font-display text-6xl md:text-7xl lg:text-8xl">{letter === " " ? <>&nbsp;</> : letter}</h1>)}</div> : <h1 className="text-darks3 uppercase font-display text-6xl md:text-7xl lg:text-8xl lg:whitespace-pre-wrap lg:vertical-rl lg:transform lg:rotate-180 lg:text-right">{heading}</h1>}
                </div>

                <div className="lg:col-start-4 lg:col-end-18 lg:row-start-2 lg:my-6">
                    <div className="flex flex-col gap-4 item">
                        {renderFaqs()}
                    </div>
                </div>

                {(faqs?.length > faqsArr) && (
                    <div className="lg:col-start-4 lg:col-span-full flex items-center justify-center mt-10 lg:mt-6">
                        <YellowButton
                            click={() => moreFaqs()}
                            text={'Load more'}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default FaqBlock;