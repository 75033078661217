import React, { useEffect, useRef, useState } from 'react'
import { RiDownloadLine } from 'react-icons/ri'
import CustomScrollbar from '../../CustomScrollbar'

import JSZip from 'jszip'
import { saveAs } from 'file-saver'

import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'
import YellowButton from '../../Buttons/YellowButton'
import WhiteButton from '../../Buttons/WhiteButton'

gsap.registerPlugin(CSSPlugin)

const PressKit = ({ block }) => {
    const [openModal, setOpenModal] = useState(false)
    const modalContainer = useRef(null)

    const handleModalOpen = () => {
        setOpenModal(!openModal)
        modalContainer.current.play()
    }

    const handleModalClose = () => {
        setOpenModal(false)
        modalContainer.current.reverse()
    }

    const handleZipDownload = async () => {
        var zip = new JSZip()

        const remoteZips = block.packages.map(async (pack) => {
            const response = await fetch(pack.file.url)
            const data = await response.blob()

            zip.file(`${pack.kitName}.zip`, data)

            return data
        })

        Promise.all(remoteZips).then(() => {
            zip.generateAsync({ type: 'blob' }).then((content) => {
                saveAs(content, `the-cycle-press-kit.zip`)
            })
        })
    }

    useEffect(() => {
        modalContainer.current = gsap.fromTo(
            modalContainer.current,
            {
                height: '0%',
                duration: 0.5,
                paused: true,
            },
            {
                height: '100%',
                duration: 0.5,
                paused: true,
            }
        )
    }, [])

    return (
        <div className="relative">
            <div className="py-24 md:pt-32 z-10 gap-y-12 md:py-28 grid grid-cols-20 auto-rows-auto lg:gap-0 lg:grid-cols-16 md:max-w-2xl lg:max-w-screen-xl mx-auto w-11/12">
                <div className="col-start-2 lg:py-10 col-end-20 lg:col-start-1 lg:col-end-8 text-white flex flex-col flex-nowrap justify-center">
                    <h2 className="font-display uppercase text-5xl lg:text-6xl mb-6">
                        {block.title}
                    </h2>
                    <p className="font-body">{block.body}</p>
                    <div className="flex flex-col md:flex-row items-start md:items-center justify-start gap-8 mt-6 lg:mt-12 overflow-visible">
                        <YellowButton
                            text="Download"
                            click={() => handleZipDownload()}
                            keypress={(e) => (e.key === "Enter") && handleZipDownload()}
                        />
                        <WhiteButton
                            text="Select"
                            click={() => handleModalOpen()}
                            keypress={(e) => (e.key === "Enter") && handleModalOpen()}
                        />
                    </div>
                </div>
                <div className="col-start-2 col-end-20 lg:col-start-10 lg:col-end-16 relative flex items-center justify-center">
                    <div
                        className="wrapper w-full bg-darks4 bg-opacity-95 clipped-img"
                        ref={modalContainer}
                    >
                        <div className="py-10 pl-14 pr-4 w-full h-full">
                            <div
                                className="absolute top-3 right-3 border overflow-hidden border-gray border-opacity-20"
                                onClick={handleModalClose}
                                onKeyPress={(e) => handleModalClose(e)}
                                role="tab"
                                tabIndex={0}
                            >
                                <div className="w-[20px] h-[20px] cursor-pointer after:absolute after:w-[100%] after:h-[100%] after:top-[14px] after:border-[1.5px] after:transform after:rotate-45 before:absolute before:w-[100%] before:h-[100%] before:border-[1.5px] before:bottom-[14px] before:transform before:rotate-45 after:border-gray before:border-gray flex items-center justify-center hover:after:border-blue hover:before:border-blue transition-all">
                                    <div className="w-1.5 h-1.5 bg-darks4 bg-opacity-95 z-20" />
                                </div>
                            </div>
                            <span className="font-body text-white mb-6 block text-lg">
                                Please select which package to download
                            </span>
                            <div className="flex flex-row flex-nowrap">
                                <CustomScrollbar
                                    style={{ width: '100%', height: 350 }}
                                >
                                    <div className="flex flex-col w-[80%]" role="tablist">
                                        {block.packages.map((pack, index) => (
                                            <a
                                                href={pack.file.url}
                                                target="_blank"
                                                rel="noreferrer"
                                                key={index}
                                                download
                                            >
                                                <div className="flex flex-row flex-nowrap justify-between items-center cursor-pointer gap-12 group">
                                                    <div className="text-offwhite border-b border-blue py-4 min-w-[150px] uppercase font-display text-lg lg:text-2xl opacity-50 group-hover:opacity-100 group-hover:border-yellow transition-all">
                                                        <h3>{pack.kitName}</h3>
                                                    </div>
                                                    <div className="rounded-full p-3 border border-blue group-hover:bg-blue transition-colors">
                                                        <RiDownloadLine className="text-white text-md group-hover:text-darks4 transition-colors" />
                                                    </div>
                                                </div>
                                            </a>
                                        ))}
                                    </div>
                                </CustomScrollbar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PressKit
