import React, { useState, memo } from 'react'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { Pagination, A11y, Controller, Navigation, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import TestCursor from '../../Cursor/TestCursor'

import 'swiper/css'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { vertResolver } from '../../../utils/vertResolver'

const FeaturedPosts = ({ block, enBlog, locale }) => {
    const { title, featuredPosts } = block
    const [slide, setSlide] = useState(1)
    const [totalSlides, setTotalSlides] = useState(0)
    const [controlledSwiper, setControlledSwiper] = useState(null)
    const [controlledSecondSwiper, setControlledSecondSwiper] = useState(null)

    const fallbackData = featuredPosts.map((post) => {
        if (!post.heading) {
            const enId = post.id.slice(0, -2).trim().concat("en");
            return enBlog.edges.filter(({ node }) => node.id === enId ? node : post)[0].node
        } else {
            return post;
        }
    });

    const getDirection = () => {
        if (typeof window !== 'undefined') {
            let windowWidth = window.innerWidth
            let direction = windowWidth <= 1024 ? 'horizontal' : 'vertical'

            return direction
        }
    }

    return (
        <div className="py-24 relative">
            <div className="grid grid-cols-20 auto-rows-auto lg:grid-cols-18 lg:max-w-screen-2xl mx-auto lg:max-w-screen">
                <div className="col-span-full lg:col-start-1 lg:col-end-2 lg:z-20 lg:flex lg:items-end">
                    {vertResolver(locale) ? <div className="flex flex-row text-center justify-center lg:block">{title.split('').map(letter => <h2 className="uppercase text-darks1 font-display text-6xl mb-6 md:text-7xl lg:text-8xl lg:mb-0">{letter === " " ? <>&nbsp;</> : letter}</h2>)}</div> : <h2 className="text-center uppercase text-darks1 font-display text-6xl mb-6 md:text-7xl lg:text-8xl lg:mb-0 lg:whitespace-pre-wrap lg:vertical-rl lg:transform lg:rotate-180">
                        {title}
                    </h2>}
                </div>

                <TestCursor className="w-11/12 mx-auto col-start-1 col-end-21 lg:z-20 lg:col-start-3 lg:col-end-13 md:max-w-2xl lg:max-w-none lg:w-full relative">
                    <Swiper
                        modules={[Pagination, A11y, Controller, Navigation, Autoplay]}
                        slidesPerView={1}
                        direction={getDirection()}
                        grabCursor={false}
                        autoplay={{
                            delay: 4000,
                            disableOnInteraction: false,
                        }}
                        navigation={{
                            nextEl: `#nextButton`,
                            prevEl: `#prevButton`
                        }}
                        pagination={{
                            el: '.pagination',
                            clickable: true,
                            renderBullet: (index, className) => {
                                return (
                                    '<div tabindex="' +
                                    index +
                                    '" class="border border-solid border-blue p-1 rounded-2xl lg:border-none md:p-0"><span class="' +
                                    className +
                                    ' bg-blue w-1 h-1 block rounded cursor-pointer transition-all"></span></div>'
                                )
                            },
                        }}
                        observer={true}
                        observeParents={true}
                        controller={{ control: controlledSecondSwiper }}
                        onSlideChange={(swiper) => {
                            setSlide(swiper.activeIndex + 1)
                        }}
                        onResize={(swiper) => {
                            swiper.changeDirection(getDirection())
                        }}
                        onSwiper={(swiper) => {
                            setControlledSwiper(swiper)
                            setSlide(swiper.activeIndex + 1)
                            setTotalSlides(swiper.slides.length)
                        }}
                        className="clipped-img go-swiper cursor-pointer"
                    >
                        {fallbackData.map((post, index) => {
                            const image = getImage(post.articleImage)
                            return (
                                <SwiperSlide
                                    key={index}
                                    className="lg:flex content-center justify-center"
                                >
                                    <GatsbyImage
                                        image={image}
                                        alt={image.alt ? image.alt : ''}
                                        className="h-full m-auto lg:w-auto lg:ml-0 lg:mr-auto"
                                    />
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </TestCursor>

                <div className="hidden lg:flex lg:justify-between lg:flex-col lg:w-full lg:col-start-13 lg:col-end-14">
                    <div className="pagination w-auto flex flex-col gap-4 lg:darks5 lg:p-2 lg:mr-0 lg:ml-auto bg-darks5" />
                    <div className="font-display lg:flex lg:flex-col gap-3">
                        <BsChevronUp
                            className="text-blue text-xl font-bold ml-auto transition-all cursor-pointer hover:scale-150" id="prevButton" />
                        <div className="font-display lg:flex lg:flex-col text-right items-end">
                            <span className="text-blue text-5xl">0{slide}</span>
                            <span className="text-darks1 text-2xl leading-none">
                                /0{totalSlides}
                            </span>
                        </div>
                        <BsChevronDown
                            className="text-blue text-xl font-bold ml-auto transition-all cursor-pointer hover:scale-150" id="nextButton" />
                    </div>
                </div>

                <TestCursor className="w-full max-w-full col-start-2 col-end-19 lg:col-start-15 lg:col-end-18 mt-8 lg:mt-10 relative z-20">
                    <Swiper
                        modules={[Controller]}
                        slidesPerView={1}
                        direction={getDirection()}
                        onResize={(swiper) => {
                            swiper.changeDirection(getDirection())
                        }}
                        onSwiper={setControlledSecondSwiper}
                        controller={{ control: controlledSwiper }}
                        className="w-full max-w-full md:max-w-2xl lg:max-w-none cursor-pointer lg:h-[28rem]"
                    >
                        {fallbackData.map((post, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <div className="text-offwhite w-full lg:flex lg:justify-end lg:flex-col lg:h-full">
                                        <span className="text-blue font-display">
                                            {post.meta.firstPublishedAt}
                                        </span>
                                        <h3 className="font-display uppercase text-2xl my-2">
                                            {post.heading}
                                        </h3>
                                        <p className="font-body">{post.excerpt}</p>
                                        <Link to={`${locale.toLowerCase() !== ("en" || undefined) ? `/news-and-media/${locale.toLowerCase()}/${post.slug}` : `/news-and-media/${post.slug}`}`} className="text-orange hover:underline mt-4">Read More</Link>
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </TestCursor>
            </div>

            <div className="absolute w-full h-full top-8 left-0 z-[-10] hidden md:block">
                <div className="relative w-full h-full z-[-10]">
                    <div className="absolute w-full top-1/2 h-16 border-t border-b hidden lg:block border-gray opacity-20" />
                </div>
            </div>
        </div>
    )
}

export default memo(FeaturedPosts)
