import React from "react";

// Blades
import DarkImage from "../Blades/DarkImage";
import DarkTextured from "../Blades/DarkTextured";
import LightImage from "../Blades/LightImage";
import LightTextured from "../Blades/LightTextured";
import Split from "../Blades/Split";
import Customisation from "../Blades/Customisation";
import FullWidthSplit from "../Blades/FullWidthSplit";

const BladeResolver = ({ blades, blog, enBlog, locale, location }) => {
  let stackIndex = 10; // Ideally, there should be no more than 10 blades on a page, this is a Z-index hack.

  const Components = {
    DatoCmsDarkImage: DarkImage,
    DatoCmsDarkTextured: DarkTextured,
    DatoCmsLightImage: LightImage,
    DatoCmsLightTextured: LightTextured,
    DatoCmsSplitBlade: Split,
    DatoCmsCustomisation: Customisation,
    DatoCmsFullWidthSplit: FullWidthSplit,
  };
  return blades.map((blade, index) => {
    if (Components[blade.__typename]) {
      stackIndex = stackIndex - 1;
      return React.createElement(Components[blade.__typename], {
        key: index,
        blog: blog,
        blade: blade,
        enBlog: enBlog,
        stack: stackIndex,
        locale: locale,
        location: location,
      });
    }

    return null;
  });
};

export default BladeResolver;
