import React from "react";
import Checkbox from "../../Checkbox";
import MainButton from "../../MainButton";

const PartnerSignUp = ({ block }) => {
  const { title, ctaText } = block;
  return (
    <div className="grid lg:grid-cols-18 max-w-screen-xl mx-auto">
      <div className="lg:col-start-1 lg:col-end-3 flex items-end">
        <h2 className="font-display text-gray uppercase w-min text-5xl whitespace-pre-wrap vertical-rl transform rotate-180 ">
          {title}
        </h2>
      </div>
      <div className="lg:col-start-3 lg:col-end-16 bg-darks5 p-3 clipped-img px-20 py-10">
        <form className="max-w-3xl m-auto ">
          <div className="font-body grid grid-cols-2 gap-8 mb-12">
            <label className="flex flex-col text-white">
              Lorem ipsum dolor sit amet, consectetur
              <input
                type="text"
                name="text"
                className="clipped-input py-6 px-6 bg-darks3 mt-4"
              />
            </label>
            <label className="flex flex-col text-white">
              Lorem ipsum dolor sit amet, consectetur
              <input
                type="text"
                name="text"
                className="clipped-input py-6 px-6 bg-darks3 mt-4"
              />
            </label>
            <label className="flex flex-col text-white">
              Lorem ipsum dolor sit amet, consectetur
              <input
                type="text"
                name="text"
                className="clipped-input py-6 px-6 bg-darks3 mt-4"
              />
            </label>
            <label className="flex flex-col text-white">
              Lorem ipsum dolor sit amet, consectetur
              <input
                type="text"
                name="text"
                className="clipped-input py-6 px-6 bg-darks3 mt-4"
              />
            </label>
          </div>
          <div className="grid grid-cols-2 gap-6">
            <label className="flex flex-row mb-4 text-darks1 text-xs">
              <div>
                <Checkbox />
              </div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </label>
            <div className="w-full flex items-end justify-end">
              <div className="w-40">
                <MainButton role="submit" type="button" text={ctaText} />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PartnerSignUp;
