import React from 'react';

import { renderNodeRule, StructuredText } from 'react-datocms';
import { isHeading, isParagraph, isList, isLink } from 'datocms-structured-text-utils';
import StructuredImage from '../../StructuredImage';

import '../../../styles/lists.scss';
import EpicBetaForm from '../../EpicBetaForm';

const StructuredContentBlock = ({ block: { content }, location, locale }) => {
    return (
        <div className="grid grid-cols-18 auto-rows-auto py-24 -mt-8 relative">
            <StructuredText
                data={content}
                renderBlock={({ record }) => {
                    switch (record.__typename) {
                        case 'DatoCmsArticleImage':
                            return (
                                <StructuredImage
                                    image={record.image}
                                    alt={record.image.alt ? record.image.alt : ''}
                                />
                            )
                        case 'DatoCmsArticleLinebreak':
                            return (
                                <div className="flex flex-row items-center mt-12 mb-20 gap-2 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12">
                                    <span className="h-0.5 w-14 bg-darks5" />
                                    <span className="h-px w-full bg-gray block" />
                                </div>
                            )
                        case 'DatoCmsEpicBetaForm':
                            return (
                                <EpicBetaForm locale={locale} location={location} />
                            );
                        default:
                            return null
                    }
                }}
                customNodeRules={[
                    renderNodeRule(
                        isHeading,
                        ({
                            node,
                            adapter: { renderNode },
                            children,
                            key,
                        }) => {
                            switch (node.level) {
                                case 1:
                                    return renderNode(
                                        `h${node.level}`,
                                        {
                                            key,
                                            className:
                                                'font-display uppercase text-5xl my-4 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12',
                                        },
                                        children
                                    )
                                case 2:
                                    return renderNode(
                                        `h${node.level}`,
                                        {
                                            key,
                                            className:
                                                'font-display uppercase text-4xl my-4 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12',
                                        },
                                        children
                                    )
                                case 3:
                                    return renderNode(
                                        `h${node.level}`,
                                        {
                                            key,
                                            className:
                                                'font-display uppercase text-3xl my-4 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12',
                                        },
                                        children
                                    )
                                case 4:
                                    return renderNode(
                                        `h${node.level}`,
                                        {
                                            key,
                                            className:
                                                'font-display uppercase text-2xl my-4 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12',
                                        },
                                        children
                                    )
                                case 5:
                                    return renderNode(
                                        `h${node.level}`,
                                        {
                                            key,
                                            className:
                                                'font-display uppercase text-xl my-4 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12',
                                        },
                                        children
                                    )
                                default:
                                    return renderNode(
                                        `h${node.level}`,
                                        {
                                            key,
                                            className:
                                                'font-display uppercase text-lg my-4 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12',
                                        },
                                        children
                                    )
                            }
                        }
                    ),
                    renderNodeRule(
                        isParagraph,
                        ({
                            node,
                            adapter: { renderNode },
                            children,
                            key,
                        }) => {
                            return renderNode(
                                `p`,
                                {
                                    key,
                                    className:
                                        'font-body my-4 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12',
                                },
                                children
                            )
                        }
                    ),
                    renderNodeRule(
                        isList,
                        ({ node, children, key }) => {
                            return (
                                <div className="col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12">
                                    <ul className="custom-list">
                                        {children}
                                    </ul>
                                </div>
                            )
                        }
                    ),
                    renderNodeRule(
                        isLink,
                        ({
                            node,
                            adapter: { renderNode },
                            children,
                            key,
                        }) => {
                            return renderNode(
                                `a`,
                                {
                                    key,
                                    className: 'text-orange',
                                    href: node.url,
                                },
                                children
                            )
                        }
                    ),
                ]} />
        </div>

    )
}

export default StructuredContentBlock;