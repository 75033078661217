import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import InnerBladeResolver from "../../InnerBladeResolve";

const Split = ({ blade, stack, location }) => {
  const image = getImage(blade.backgroundImage);
  let notch =
    blade.bottomNotch && blade.bottomNotch.length
      ? JSON.parse(blade.bottomNotch).toString().toLowerCase()
      : "";

  return (
    <section
      className={`relative bg-darks5 -mt-10 ${
        notch ? `notch-${notch}` : null
      } stacking-context-${stack}`}
      key={blade.id}
    >
      <div className="grid grid-cols-1 z-20 relative m-auto lg:grid-cols-18 max-w-screen-2xl">
        <InnerBladeResolver {...blade} location={location} />
      </div>
      {image ? (
        <GatsbyImage
          image={image}
          alt={blade.backgroundImage.alt ? blade.backgroundImage.alt : ""}
          className="!absolute top-0 z-0 w-full h-full filter brightness-50 lg:w-8/12"
        />
      ) : null}
    </section>
  );
};

export default Split;
