import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { BsChevronRight } from 'react-icons/bs';
import WhiteButton from '../../Buttons/WhiteButton';
import YellowButton from '../../Buttons/YellowButton';

const AffliatesBlock = ({ block: { title, body } }) => {
    const [affiliatesShown, setAffiliatesShown] = useState(20);

    const { affiliates } = useStaticQuery(graphql`
        query Affiliates {
            affiliates: allDatoCmsAffiliate(filter: {locale: {eq: "en"}}) {
                edges {
                    node {
                        id
                        affiliateUrl
                        affiliateName
                    }
                }
            }
        }
    `);

    return (
        <div className="py-20 lg:pt-28 relative">
            <div className="lg:max-w-screen-2xl lg:max-w-screen mx-auto w-11/12">
                <div className="max-w-[650px] mx-auto text-center">
                    <h2 className="font-display text-5xl lg:text-7xl uppercase text-darks4">{title}</h2>
                    <p>{body}</p>
                </div>

                <div className={`grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 max-w-6xl mx-auto my-10 lg:my-12 xl:my-16`}>
                    {affiliates.edges.map(({ node: { affiliateName, affiliateUrl } }, index) => {
                        return (
                            <div className={`${affiliatesShown < (index + 1) ? 'hidden' : 'block'} bg-white py-2 px-4 group hover:scale-105 transition-all hover:bg-darks2 hover:text-white`}>
                                <a href={affiliateUrl} target="_blank" rel="noreferrer noopenner" className="flex flex-row justify-between items-center flex-nowrap">
                                    <span className="font-body text-xl">{affiliateName}</span>
                                    <div className="p-2 bg-gray">
                                        <BsChevronRight className="text-darks5" />
                                    </div>
                                </a>
                            </div>
                        )
                    })}
                </div>

                <div className={`max-w-[225px] mx-auto ${affiliatesShown > affiliates.edges.length ? 'hidden' : 'block'}`}>
                    <YellowButton {...{
                        text: 'Show More',
                        click: () => setAffiliatesShown(affiliatesShown + 20),
                    }} />
                </div>
            </div>
        </div>
    )
}

export default AffliatesBlock;