import React from 'react'
import ReactPlayer from 'react-player'
import WhiteButton from '../../Buttons/WhiteButton'

import { useLightboxState } from '../../../context'

import { getImage, GatsbyImage } from 'gatsby-plugin-image'

const VideoHero = ({
    block: {
        buttonVideoEmbed,
        ctaText,
        heading,
        heroVideo,
        heroVideoEmbed,
        id,
        videoThumbnail,
    },
}) => {
    const {
        setLightboxOpen,
        setLightboxImages,
        setLightboxVideo,
        setDownloadAllowed,
    } = useLightboxState()

    const handleLightbox = () => {
        const { url, provider, thumbnailUrl, title } = buttonVideoEmbed || {}

        setLightboxOpen(true)
        setDownloadAllowed(false)
        setLightboxImages([])
        setLightboxVideo({
            url,
            provider,
            thumbnailUrl,
            title,
        })
    }

    const image = getImage(videoThumbnail)

    return (
        <>
            {videoThumbnail && !heroVideo && !heroVideoEmbed && (
                <>
                    <GatsbyImage
                        image={image}
                        alt={videoThumbnail.alt ? videoThumbnail.alt : ''}
                        className="min-h-[425px] w-full"
                    />
                    {heading ? (
                        <div className="absolute w-11/12 lg:10/12 text-center font-display uppercase text-4xl md:text-6xl lg:text-8xl font-extrabold text-white mt-10">
                            <h1>{heading}</h1>
                        </div>
                    ) : null}
                </>
            )}
            {heroVideo && (
                <div className="player-wrapper w-full relative overflow-hidden">
                    <video
                        controls={false}
                        loop
                        autoPlay
                        muted
                        playsInline
                        className="w-full h-full object-contain"
                    >
                        <source src={heroVideo.video.mp4Url} type="video/mp4" />
                        <img
                            src={heroVideo.video.thumbnailUrl}
                            title="Your browser does not support the <video> tag"
                            alt={
                                heroVideo.alt
                                    ? heroVideo.alt
                                    : 'Hero video alternative text.'
                            }
                        />
                    </video>
                </div>
            )}

            {heroVideoEmbed && !videoThumbnail && (
                <div className="video-container h-[65vh] md:h-[80vh] relative w-full">
                    <ReactPlayer
                        url={heroVideoEmbed.url}
                        playing
                        controls={false}
                        loop={true}
                        muted={true}
                        playsinline={true}
                        width="100%"
                        height="100%"
                        config={{
                            youtube: {
                                playerVars: {
                                    modestBranding: 1,
                                    showinfo: 1,
                                    fs: 1,
                                    disablekb: 1,
                                    controls: 0,
                                },
                            },
                        }}
                    />
                </div>
            )}

            {ctaText && !heroVideo && (
                <div className="absolute bottom-4 md:bottom-14 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <WhiteButton
                        text={ctaText ? ctaText : 'Watch Trailer'}
                        click={handleLightbox}
                    />
                </div>
            )}
        </>
    )
}

export default VideoHero
