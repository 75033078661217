import { useState, useEffect } from "react";

const useVideoPlayer = (videoElement) => {
    const [playerState, setPlayerState] = useState({
        isPlaying: false,
        progress: 0,
        speed: 1,
        isMuted: false,
        isHovered: false,
    });

    const togglePlay = () => {
        setPlayerState({
            ...playerState,
            isPlaying: !playerState.isPlaying
        });
    };

    useEffect(() => {
        playerState.isPlaying ? videoElement.current.play() : videoElement.current.pause();
    }, [playerState.isPlaying, videoElement]);

    const handleOnTimeUpdate = () => {
        const progress = (videoElement.current.currentTime / videoElement.current.duration) * 100;

        setPlayerState({
            ...playerState,
            progress
        });
    }

    const handleVideoProgress = (event) => {
        const manualChange = Number(event.target.value);

        videoElement.current.currentTime = (videoElement.current.duration / 100) * manualChange;
        setPlayerState({
            ...playerState,
            progress: manualChange
        });
    };

    const handleVideoSpeed = (event) => {
        const speed = Number(event.target.value);

        videoElement.current.playbackRate = speed;
        setPlayerState({
            ...playerState,
            speed
        });
    };

    const toggleMute = () => {
        setPlayerState({
            ...playerState,
            isMuted: !playerState.isMuted
        });
    }

    const toggleToolbar = (state) => {
        if (state) {
            setPlayerState({
                ...playerState,
                isHovered: state
            });
        } else {
            setTimeout(function () {
                setPlayerState({
                    ...playerState,
                    isHovered: state
                })
            }, 500)
        }
    }

    useEffect(() => {
        playerState.isMuted ? (videoElement.current.muted = true) : (videoElement.current.muted = false);
    }, [playerState.isMuted, videoElement]);


    return {
        playerState,
        togglePlay,
        toggleToolbar,
        handleOnTimeUpdate,
        handleVideoProgress,
        handleVideoSpeed,
        toggleMute,
    }
}

export default useVideoPlayer;