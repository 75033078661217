import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from 'gbimage-bridge';
import ReactMarkdown from 'react-markdown';


const TextHero = ({ block: { backgroundImage, heroTitle, heroTextOne, heroTextTwo } }) => {

    const background = getImage(backgroundImage.gatsbyImageData);
    const convertBackground = convertToBgImage(background);

    return (
        <BackgroundImage Tag="section" {...convertBackground} preserveStackingContext>
            <div className="min-h-[500px] md:h-[625px] lg:min-h-[700px] grid grid-cols-18 py-28 bg-darks5 bg-opacity-50 mx-auto">
                <div className="max-w-screen-xl mx-auto w-full h-full flex flex-col justify-center col-start-2 col-end-13 lg:col-start-4">
                    <h1 className="text-white font-display uppercase text-5xl lg:text-6xl max-w-[400px]">
                        {heroTitle}
                    </h1>
                    <div className="flex flex-col md:flex-row text-white mt-8 gap-6 lg:gap-20">
                        <ReactMarkdown className="markdown">
                            {heroTextOne}
                        </ReactMarkdown>
                        <ReactMarkdown className="markdown">{heroTextTwo}</ReactMarkdown>
                    </div>
                </div>
            </div>
        </BackgroundImage>
    )
}

export default TextHero;