import React, { useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { graphql, useStaticQuery } from "gatsby";

// SVGS
import Steam from "../../assets/svgs/steam.svg";
import EpicGames from "../../assets/svgs/epic-games.svg";
import YellowButton from "../Buttons/YellowButton";

const PlatformDropDown = ({ description, cta }) => {
  const { stores } = useStaticQuery(graphql`
    query PlatformDropdownQuery {
      stores: allDatoCmsGlobalConfig(filter: { locale: { eq: "en" } }) {
        edges {
          node {
            epicStoreUrl
            steamUrl
          }
        }
      }
    }
  `);

  const [dropdown, setDropdown] = useState(false);
  const [provider, setProvider] = useState("steam");
  const [select] = useState({
    steam: {
      link: stores.edges[0].node.steamUrl,
      text: "Steam",
      icon: <Steam className="max-w-[25px] w-[25px] h-[25px] fill-current" />,
    },
    epicgames: {
      link: stores.edges[0].node.epicStoreUrl,
      text: "Epic Games",
      icon: (
        <EpicGames className="max-w-[25px] w-[25px] h-[25px] fill-current" />
      ),
    },
  });

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const handleKeyDown = (event) => {
    if (event.which === 13) {
      setDropdown(!dropdown);
    }
  };

  const handleSelection = (event) => {
    const target = event.target;
    const name = target.getAttribute("name");
    setProvider(name);
    setDropdown(!dropdown);
  };

  const handleKeySelection = (event) => {
    if (event.which === 13) {
      const target = event.target;
      const name = target.getAttribute("name");
      setProvider(name);
      setDropdown(!dropdown);
    }
  };

  return (
    <div>
      {description && <p className="m-0 mb-4 font-body">{description}</p>}
      <div className="relative z-40">
        <div
          className={` bg-darks3 w-full p-2 flex flex-row justify-between items-center gap-4 ${
            dropdown ? "bg-offwhite" : "bg-darks3"
          } transition-all`}
          onClick={handleDropdown}
          role="tablist"
          tabIndex={0}
          onKeyPress={(e) => handleKeyDown(e)}
        >
          {
            <div
              className={`flex flex-row justify-between w-full pl-4 ${
                dropdown ? "text-darks3" : "text-gray"
              }`}
            >
              {select[provider].text}
              {select[provider].icon}
            </div>
          }
          <div className={`w-min p-4 ${dropdown ? "bg-white" : "bg-darks5"}`}>
            <BsChevronDown
              className={`${
                dropdown ? "text-darks1 rotate-180" : "text-blue rotate-0"
              } transition-all`}
            />
          </div>
        </div>
        <div
          className={`${
            dropdown ? "flex z-40" : "hidden"
          } flex-col absolute w-full`}
        >
          <div
            className="p-4 pl-6 bg-darks3 w-full hover:bg-offwhite group-hover text-gray hover:text-darks3 transition-all flex items-center justify-between"
            onClick={(event) => handleSelection(event)}
            onKeyPress={(e) => handleKeySelection(e)}
            name="steam"
            role="tab"
            tabIndex={0}
          >
            Steam
            <Steam className="max-w-[30px] w-[30px] h-[30px] fill-current" />
          </div>
          <div
            className="p-4 pl-6 bg-darks3 w-full hover:bg-offwhite text-gray hover:text-darks3 transition-all flex items-center justify-between"
            onClick={(event) => handleSelection(event)}
            onKeyPress={(e) => handleKeySelection(e)}
            name="epicgames"
            role="tab"
            tabIndex={0}
          >
            Epic Games
            <EpicGames className="max-w-[30px] w-[30px] h-[30px] fill-current" />
          </div>
        </div>
      </div>
      <div className="max-w-[17rem] mt-14">
        <YellowButton
          link={select?.[provider]?.link || select?.steam?.link}
          text={cta ? cta : "Wishlist"}
        />
      </div>
    </div>
  );
};

export default PlatformDropDown;
