import React from "react";

import Steam from "../../../assets/svgs/steam.svg";
import EpicGames from "../../../assets/svgs/epic-games.svg";
import PlatformDropDown from "../../PlatformDropdown";

const Play = ({ block }) => {
  const { heading, id, inputDescription, ctaText } = block;
  return (
    <div id={id} className="pb-14 pt-[5.5rem] md:pt-32 lg:pb-20 lg:pt-32 grid grid-cols-20 lg:grid-cols-8 mx-auto lg:col-start-1 lg:col-end-8 w-full lg:w-11/12 max-w-lg md:mb-[6rem]">
      <div className="col-start-2 col-end-20 lg:col-span-8 mb-6 lg:mb-0">
        <h2 className="font-display uppercase text-gray text-5xl md:text-6xl">{heading}</h2>
      </div>
      <div className="clipped-img pb-8 pt-10 pr-8 pl-12 bg-darks5 text-gray col-start-2 col-end-20 lg:col-start-1 lg:col-end-8 cursor-pointer">
        <div className="flex flex-row gap-6 mb-10">
            <EpicGames className="max-w-[50px] w-[50px] h-[50px] fill-current text-gray" />
            <Steam className="max-w-[50px] w-[50px] h-[50px] fill-current text-gray" />
        </div>
        <PlatformDropDown description={inputDescription} cta={ctaText} />
      </div>
    </div>
  );
};

export default Play;
