import React from 'react';
import Card from './Card';

const CardBlock = ({ block }) => {
    return (
        <div className="py-24 md:pt-28 relative" id={block.id}>
            <div className="grid lg:grid-cols-18 max-w-screen-xl mx-auto relative">
                {(block.title || block.body) && (
                    <div className="col-span-full text-center mb-10 max-w-[450px] mx-auto">
                        {block.title && (
                            <h2 className="text-darks1 font-display uppercase text-5xl lg:text-6xl mb-6">
                                {block.title}
                            </h2>
                        )}
                        {block.body && (
                            <p className="font-body text-offwhite">{block.body}</p>
                        )}
                    </div>
                )}

                <div className="lg:col-start-2 lg:col-end-18 grid grid-cols-1 md:grid-cols-18 gap-6 relative">
                    {block.cards.map((card, index) => <Card {...card} key={index} />)}
                </div>
            </div>

            <div className="absolute z-[-10] w-full h-full top-0 left-0 hidden lg:block">
                <div className="h-14 border-t border-b border-darks1 border-opacity-50 bottom-1/4 absolute w-full">
                    <div className="relative w-full h-full">
                        <div className="flex flex-row flex-nowrap gap-1 absolute bottom-2 left-14">
                            <span className="block w-1 h-1 bg-blue shadow-blue rounded-full" />
                            <span className="block w-1 h-1 bg-blue shadow-blue rounded-full" />
                        </div>

                        <div className="flex flex-row flex-nowrap gap-1 absolute -bottom-4 left-20">
                            <span className="block w-0.5 h-2 bg-blue shadow-blue rounded-full" />
                            <span className="block w-0.5 h-2 bg-blue shadow-blue rounded-full" />
                        </div>
                    </div>
                </div>

                <div className="border-l border-b border-blue shadow-blue p-1 w-[200px] h-[200px] top-0 right-0 absolute">
                    <div className="relative w-full h-full">
                        <div className="flex flex-row flex-nowrap gap-1 absolute top-8 right-8">
                            <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
                            <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
                            <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
                        </div>

                        <div className="flex flex-col flex-nowrap gap-1 left-2 bottom-1/4 absolute">
                            <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
                            <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
                        </div>

                        <div className="flex flex-col flex-nowrap gap-1 -left-6 bottom-6 absolute">
                            <span className="block bg-blue rounded-full w-2 h-1 shadow-blue" />
                            <span className="block bg-blue rounded-full w-2 h-1 shadow-blue" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardBlock;