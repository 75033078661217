import React from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const BasicHero = ({ block: { backgroundImage, logoOrText, logo, body } }) => {

    const bg = getImage(backgroundImage.gatsbyImageData);
    const heroBackground = convertToBgImage(bg);

    const heroLogo = (!logoOrText && logo) ? getImage(logo.gatsbyImageData) : null;

    return (
        <BackgroundImage
            tag="section"
            {...heroBackground}
            className="stacking-context-9"
            style={{
                backgroundPosition: 'center',
                width: '100%'
            }}>

            <div className="min-h-[500px] md:h-[625px] lg:min-h-[700px] flex items-center justify-center relative">
                {(!logoOrText && logo) ? (
                    <GatsbyImage image={heroLogo} alt={"The Cycle: Frontier Logo"} className="max-w-[450px] w-10/12 mx-auto" />
                ) : null}
                {(logoOrText && body) ? (
                    <h1 className="text-white font-display text-center uppercase text-5xl lg:text-6xl max-w-[600px]">{body}</h1>
                ) : null}
                <div className="bg-darks5 bg-opacity-50 absolute w-full h-full z-[-10]" />
            </div>
        </BackgroundImage>
    )
}

export default BasicHero