import { useStaticQuery, graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import { useLightboxState } from '../../../context'
import YellowButton from '../../Buttons/YellowButton'
import ItemDownload from '../../ItemDownload'
import MediaCategory from '../../MediaCategory'

const MediaDownloads = ({ block }) => {
    const [number, setNumber] = useState(6)
    const [categories, setCategories] = useState(['All'])
    const [filter, setFilter] = useState()

    const { setLightboxImages, setLightboxOpen, setActiveLightboxImage, setDownloadAllowed } =
        useLightboxState()

    const data = useStaticQuery(graphql`
        query DownloadableAssets {
            allDatoCmsMediaAndDownload(filter: { locale: { eq: "en" } }) {
                edges {
                    node {
                        category
                        categoryGallery {
                            gatsbyImageData(placeholder: BLURRED)
                            url
                            filename
                            video {
                                mp4Url
                                thumbnailUrl
                                streamingUrl
                            }
                        }
                    }
                }
            }
        }
    `)

    const chunkArray = (array, chunk_size) => {
        var index = 0
        var arrLength = array.length
        var tempArr = []

        for (index = 0; index < arrLength; index += chunk_size) {
            var myChunk = array.slice(index, index + chunk_size)
            tempArr.push(myChunk)
        }

        return tempArr
    }

    const galleryArr = data.allDatoCmsMediaAndDownload.edges.map(
        (edge, index) => {
            const { node } = edge
            if (!categories.includes(node.category)) {
                setCategories([...categories, node.category])
            }
            return node.categoryGallery.map((gallery) => {
                return {
                    category: node.category,
                    image: getImage(gallery),
                    filename: gallery.filename,
                    download: gallery.url,
                    video: gallery.video,
                }
            })
        }
    )

    const renderGallery = () => {
        let filtered, arrayChunks
        const newArr = galleryArr.flat() // Flatten the Gallery Array

        if (filter) {
            filtered = galleryArr
                .map((gallery) => {
                    return gallery.filter((item) => item.category === filter)
                })
                .flat()
        }

        arrayChunks = filtered?.length
            ? chunkArray(filtered.slice(0, number), 6)
            : chunkArray(newArr.slice(0, number), 6)

        return arrayChunks.map((chunk) => {
            return chunk.map((galleryItem, index) => (
                <ItemDownload
                    key={index}
                    {...galleryItem}
                    click={() => handlePushMedia(index)}
                />
            ))
        })
    }

    const increaseMedia = () => setNumber(number + 3)

    const handleFilter = (event) => {
        setNumber(6) // reset laod more state
        setFilter(event.target.dataset.name)
    }

    const handleKeyPressFilter = (event) => {
        if (event.key === "Enter") {
            setNumber(6);
            setFilter(event.target.dataset.name);
        }
    }

    const handlePushMedia = (index) => {
        let mediaItems
        const newArr = galleryArr.flat()

        if (filter) {
            mediaItems = galleryArr
                .map((gallery) => {
                    return gallery.filter((item) => item.category === filter)
                })
                .flat()
        }

        // Clicked item passed as index to help activeLightboxImage on initial load
        setActiveLightboxImage(index)
        setLightboxImages(filter ? mediaItems : newArr)
        setDownloadAllowed(true);
        setLightboxOpen(true)
    }

    return (
        <div className="py-20 lg:pt-28 relative">
            <div className="flex flex-col items-center max-w-screen-xl mx-auto w-11/12 z-40">
                <div className="lg:grid lg:grid-cols-18 lg:auto-rows-auto z-40 w-full">
                    <div className="lg:col-span-full lg:h-full lg:relative mb-6 z-40">
                        <h2 className="font-display text-5xl lg:text-7xl uppercase text-darks1">
                            {block.title}
                        </h2>
                    </div>
                    <div
                        className={`lg:col-span-full gap-4 flex flex-row flex-wrap items-center`}
                    >
                        {categories.map((category, index) => {
                            let categoryImage
                            galleryArr.map((gallery) => {
                                if (
                                    gallery[0] &&
                                    gallery[0].category === category
                                ) {
                                    return categoryImage = gallery[0].image
                                }
                                return null;
                            })
                            return (
                                <MediaCategory
                                    key={index}
                                    category={category}
                                    image={categoryImage}
                                    click={(e) => handleFilter(e)}
                                    keypress={(e) => handleKeyPressFilter(e)}
                                    selected={filter === category}
                                />
                            )
                        })}
                    </div>
                    <div className="lg:col-start-2 lg:col-end-20 md:grid md:grid-cols-18 flex flex-col gap-8 my-12">
                        {renderGallery()}
                    </div>
                </div>
                {galleryArr?.flat()?.length > number ? (
                    <YellowButton
                        text={block.ctaText ? block.ctaText : 'Load more'}
                        className="z-40"
                        click={() => increaseMedia()}
                    />
                ) : null}
            </div>

            <div className="absolute w-full h-full top-8 left-0 z-[-10] hidden lg:block">
                <div className="relative w-full h-full z-0">
                    <div className="absolute w-full top-[32%] h-16 border-t border-b border-gray border-opacity-20 z-0">
                        <div className="absolute top-[39%] left-20 flex flex-col flex-nowrap gap-0.5">
                            <span className="block h-0.5 w-3 rounded bg-blue shadow-blue" />
                            <span className="block h-0.5 w-3 rounded bg-blue shadow-blue" />
                            <span className="block h-0.5 w-3 rounded bg-blue shadow-blue" />
                            <span className="block h-0.5 w-3 rounded bg-blue shadow-blue" />
                        </div>
                    </div>
                    <div className="absolute w-16 h-[325px] border-t border-r border-b border-blue top-1/4 z-0">
                        <div className="absolute bottom-4 right-1 flex flex-col flex-nowrap gap-1">
                            <span className="block h-0.5 w-2 rounded bg-blue shadow-blue" />
                            <span className="block h-0.5 w-2 rounded bg-blue shadow-blue" />
                        </div>

                        <div className="absolute bottom-10 -right-3 flex flex-col flex-nowrap gap-1 z-0">
                            <span className="block h-1 w-1 rounded bg-blue shadow-blue" />
                            <span className="block h-1 w-1 rounded bg-blue shadow-blue" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MediaDownloads
