import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useEffect, useRef } from "react";

import { gsap } from "gsap";
import { CSSPlugin } from "gsap/all";
import { Link } from "gatsby";
import { useWindowDimension } from "../../hooks/useWindowDimensions";

gsap.registerPlugin(CSSPlugin);

const BlogItem = ({ heading, excerpt, articleImage, meta, category, slug, locale }, props) => {
  const container = useRef();
  const quadLines = useRef();
  const darkLine = useRef();
  const lightLine = useRef();
  const imageContainer = useRef();
  const trioContainer = useRef();
  const outline = useRef();

  const [width] = useWindowDimension();

  useEffect(() => {
    container.current = gsap.fromTo(
      imageContainer.current,
      {
        duration: 0.2,
        paused: true,
        y: 0,
        x: 0,
      },
      {
        duration: 0.2,
        paused: true,
        y: -8,
        x: -8,

        onComplete: function () {
          gsap.fromTo(
            outline.current,
            {
              duration: 0.2,
              scale: 1,
              opacity: 0,
              display: "none",
            },
            {
              duration: 0.2,
              display: "block",
              scale: 1,
              opacity: 1,
            }
          );
        },
        onReverseComplete: function () {
          gsap.to(outline.current, {
            duration: 0.4,
            scale: 1.05,
            opacity: 0,
          });
        },
      }
    );

    trioContainer.current = gsap.fromTo(
      trioContainer.current.childNodes[0].children,
      {
        duration: 0.2,
        paused: true,
        width: "0%",
        stagger: {
          each: 0.2,
        },
      },
      {
        duration: 0.2,
        paused: true,
        width: "0.75rem",
        stagger: 0.2,
      }
    );

    quadLines.current = gsap.fromTo(
      quadLines.current.children,
      {
        duration: 0.2,
        paused: true,
        opacity: 0,
        y: (index, target) => {
          let position = index * 7;
          position = -position;

          return position;
        },
        stagger: {
          each: 0.1,
        },
      },
      {
        duration: 0.2,
        paused: true,
        opacity: 1,
        y: 0,
        stagger: {
          each: 0.1,
        },
      }
    );

    darkLine.current = gsap.fromTo(
      darkLine.current,
      {
        duration: 1,
        paused: true,
        width: "0%",
      },
      {
        duration: 1,
        width: "3.5rem",
      }
    );

    lightLine.current = gsap.fromTo(
      lightLine.current,
      {
        duration: 1,
        paused: true,
        width: "0%",
      },
      {
        duration: 1,
        width: "100%",
      }
    );
  }, []);

  const onMouseEnterHandler = () => {
    if (width > 1024) {
      container.current.play();
      quadLines.current.play();
      darkLine.current.play();
      lightLine.current.play();
      trioContainer.current.play();
    }

    return;
  };

  const onMouseLeaveHandler = () => {
    if (width > 1024) {
      container.current.reverse();
      quadLines.current.reverse();
      darkLine.current.reverse();
      lightLine.current.reverse();
      trioContainer.current.reverse();
    }

    return;
  };

  const image = getImage(articleImage);

  return (
    <div
      className="group blog-item lg:col-start-1 lg:col-end-10 lg:child(2n):col-start-10 lg:child(2n):col-end-19 mb-12 md:mb-0 relative cursor-pointer transition-all"
      data-name={category?.toString()}
      ref={container}
      onMouseOver={onMouseEnterHandler}
      onMouseOut={onMouseLeaveHandler}
      onBlur={onMouseEnterHandler}
      onFocus={onMouseLeaveHandler}
      role="tab"
      tabIndex={0}
    >
      <Link to={`${locale.toLowerCase() !== ("en" || undefined) ? `/news-and-media/${locale.toLowerCase()}/${slug}` : `/news-and-media/${slug}`}`}>
        <div className="relative">
          <div
            className="relative transition-all group-hover:transform group-hover:-translate-x-4 group-hover:-translate-y-4 z-10"
            ref={imageContainer}
          >
            <div
              className="border news-border border-blue absolute pointer-events-none -top-2 -left-2 w-full h-full z-10 opacity-0 clipped-news-img"
              ref={outline}
            />
            <GatsbyImage
              image={image}
              alt={articleImage.alt ? articleImage.alt : ''}
              className="h-full clipped-news-img"
            />
            <span className="absolute top-0 right-0 bg-darks5 bg-opacity-50 py-1 px-4 text-gray z-20 font-body tracking-widest">
              {meta.firstPublishedAt}
            </span>
          </div>
          <div className="absolute top-0 w-full h-full clipped-news-img bg-gray bg-opacity-70 z-0" />
          <div className="hidden lg:group-hover:block absolute h-full w-full top-0 left-0 z-[-10]">
            <div className="relative w-full h-full">
              <div className="absolute bottom-6 -left-3">
                <span className="block h-1 w-1 rounded bg-darks3 mb-1" />
                <span className="block h-1 w-1 rounded bg-darks3" />
              </div>
              <div className="absolute -bottom-4 right-4 flex flex-row flex-nowrap items-center justify-between w-20">
                <span className="block h-2 w-8 bg-darks3 clipped-accent" />
                <div
                  className="flex flex-row flex-nowrap gap-1"
                  ref={trioContainer}
                >
                  <div className="flex flex-col flex-nowrap gap-1">
                    <span className="block h-0.5 w-3 rounded bg-darks3" />
                    <span className="block h-0.5 w-3 rounded bg-darks3" />
                  </div>
                  <span className="block h-0.5 w-0.5 rounded bg-darks3" />
                </div>
              </div>
              <div
                className="absolute bottom-0 -right-4 flex flex-col gap-1 y-[-7px]"
                ref={quadLines}
              >
                <span className="line block h-[3px] w-3 rounded bg-darks3" />
                <span className="line block h-[3px] w-3 rounded bg-darks3" />
                <span className="line block h-[3px] w-3 rounded bg-darks3" />
                <span className="line block h-[3px] w-3 rounded bg-darks3" />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 text-darks1">
          <div className="flex flex-row flex-nowrap justify-between items-center gap-x-4 mb-2">
            <h3 className="font-display text-3xl uppercase w-half max-w-[300px]">
              {heading}
            </h3>
            <div className="hidden lg:group-hover:flex flex-row flex-nowrap gap-2 w-1/2">
              <span className="h-0.5 bg-darks5 block" ref={darkLine} />
              <span className="h-px bg-gray block" ref={lightLine} />
            </div>
          </div>
          <p className="font-body">{excerpt}</p>
        </div>

        <div className="absolute hidden md:block w-full h-full top-0 z-0">
          <div className="relative w-full h-full">
            <div className="absolute decal top-24">
              <span className="block h-1 w-3 rounded bg-darks3 mb-1" />
              <span className="block h-1 w-3 rounded bg-darks3" />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BlogItem;
