// import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import WhiteButton from "../../Buttons/WhiteButton";

const LinkSplit = ({ block, index, locale }) => {
  const image = getImage(block.link.articleImage);

  const link = block.linkPrefix ? `${block.linkPrefix}/${block.link.slug}` : `/${block.link.slug}`

  return (
    <div className="lg:col-span-9 flex flex-col items-center justify-center relative group min-h-[300px] overflow-hidden">
      <div className="absolute z-10 flex flex-col text-center items-center justify-center">
        <h2 className="mb-6 font-display text-white uppercase text-5xl xl:text-6xl max-w-xl">{block.link.heading}</h2>
        <WhiteButton text={block.ctaText ? block.ctaText : "Read more"} link={`${locale.toLowerCase() !== ("en" || undefined) ? `/${locale.toLowerCase()}${link}` : `${link}`}`} />
      </div>
      <div className="w-full h-full absolute bottom-[-100%] group-hover:bottom-0 transition-all" style={{ backgroundImage: `linear-gradient(to top, ${block.gradientOverlay.rgb}, transparent 60%, transparent)` }} />
      <GatsbyImage image={image} alt={block.link.articleImage.alt ? block.link.articleImage.alt : ''} className={`h-full z-[-10] ${index === 0 ? 'pt-[3.2rem] lg:pt-0' : null}`} />
    </div>
  );
};

export default LinkSplit;
