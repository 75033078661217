import React, { useState } from 'react'
import { BsChevronDown } from 'react-icons/bs'

const Dropdown = ({
    filters = [],
    placeholder,
    click,
    keypress,
    className,
    selected,
    classNames = {},
}) => {
    const [isOpen, setOpen] = useState(false)

    return (
        <div
            className="relative z-40 cursor-pointer font-body"
            onClick={() => setOpen(!isOpen)}
        >
            <div
                className={`w-full p-2 flex flex-row justify-between items-center gap-4 ${
                    isOpen ? 'bg-darks5 text-white' : 'bg-white'
                } transition-all`}
            >
                <p className="pl-4 min-w-[150px]">
                    {selected ? selected : placeholder}
                </p>

                <div
                    className={`w-min p-4 ${
                        isOpen ? 'bg-darks2' : 'bg-gray border border-darks4'
                    }`}
                >
                    <BsChevronDown
                        className={`${
                            isOpen
                                ? 'rotate-180 text-white'
                                : 'rotate-0 text-darks5'
                        } transition-all`}
                    />
                </div>
            </div>

            <div
                className={`${
                    isOpen ? 'flex z-50' : 'hidden'
                } flex-col absolute w-full ${
                    classNames?.options || 'max-h-[175px]'
                } overflow-y-scroll`}
            >
                {filters.map((filter, index) => {
                    return (
                        <div
                            className={`p-4 pl-6 bg-white hover:bg-darks3 hover:text-white text-darks4 transition-all flex items-center justiy-between ${
                                classNames?.option || ''
                            }`}
                            onClick={(e) => click(e)}
                            data-name={filter}
                        >
                            <p>{filter}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Dropdown
