import { getImage } from 'gatsby-plugin-image'
import React from 'react'

import BasicHero from '../../Hero/BasicHero'
import VideoHero from '../../Hero/VideoHero'
import TextHero from '../../Hero/TextHero'

const LightImage = ({ blade, stack }) => {
    const block = blade.block[0].heroType[0]

    let notch =
        blade.bottomNotch && blade.bottomNotch.length
            ? JSON.parse(blade.bottomNotch).toString().toLowerCase()
            : ''

    const Heros = {
        DatoCmsVideoHero: VideoHero,
        DatoCmsBasicHero: BasicHero,
        DatoCmsTextHero: TextHero,
    }

    return (
        <section
            className={`LightImage relative flex flex-col items-center justify-center z-20 ${
                notch ? `notch-${notch}` : null
            } stacking-context-${stack} ${
                block.videoThumbnail &&
                !block.heroVideo &&
                !block.heroVideoEmbed
                    ? 'max-h-[90vh]'
                    : null
            }`}
        >
            {Heros[block.__typename]
                ? React.createElement(Heros[block.__typename], {
                      key: block.__typename,
                      block: block,
                  })
                : null}
        </section>
    )
}

export default LightImage
