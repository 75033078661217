import React from "react";

const Checkbox = ({className, checked, ...props}) => {
  return (
    <div className="relative border-blue border w-[20px] h-[20px] cursor-pointer flex items-center justify-center flex-col rounded-sm overflow-hidden mr-3 transform transition-all hover:after:absolute hover:after:w-[100%] hover:after:h-[100%] hover:after:border-blue hover:after:border-[1.5px] hover:after:top-[12px] hover:after:transform hover:after:rotate-45 hover:before:absolute hover:before:w-[100%] hover:before:h-[100%] hover:before:border-blue hover:before:border-[1.5px] hover:before:bottom-[12px] hover:before:transform hover:before:rotate-45" 
      onClick={() => props.onChange(!checked)}
      onKeyPress={(e) => (e.key === "Event") & props.onChange(!checked) }
      role="checkbox"
      aria-checked={checked}
      tabIndex={0}
      >
      <div className={`relative z-10 ${checked ? `w-2 h-2 bg-white` : `w-1 h-1 bg-darks5`}`} />
      <input
        type="checkbox"
        className="hidden"
        checked={checked}
        {...props}
      />
    </div>
  );
};

export default Checkbox;
