import { useState, useEffect } from 'react';

export function useWindowDimension() {
    var windowWidth, windowHeight;
    if (typeof window !== "undefined") {
        windowWidth = window.innerWidth;
        windowHeight = window.innerHeight;
    }
    const [dimension, setDimension] = useState([
        windowWidth, windowHeight
    ]);

    useEffect(() => {
        const debouncedResizeHandler = debounce(() => {
            setDimension([windowWidth, windowHeight]);
        }, 100); // 100ms
        window.addEventListener('resize', debouncedResizeHandler);
        return () => window.removeEventListener('resize', debouncedResizeHandler);
    }, [windowWidth, windowHeight]); // Note this empty array. this effect should run only on mount and unmount
    return dimension;
}

function debounce(fn, ms) {
    let timer;
    return _ => {
        clearTimeout(timer);
        timer = setTimeout(_ => {
            timer = null;
            fn.apply(this, arguments);
        }, ms);
    };
}