import React, { useState } from 'react'

import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'

import { Pagination, A11y, Controller, Navigation, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useMouseState } from '../../../context'

import TestCursor from '../../Cursor/TestCursor'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import ReactMarkdown from 'react-markdown'
import 'swiper/css'
import { vertResolver } from '../../../utils/vertResolver'

const WhiteCarousel = ({ block, locale }) => {
    const [slide, setSlide] = useState(1)
    const [totalSlides, setTotalSlides] = useState(0)
    const [controlledSwiper, setControlledSwiper] = useState(null)
    const [controlledSecondSwiper, setControlledSecondSwiper] = useState(null)

    const { cursorChangeHandler } = useMouseState()

    const getDirection = () => {
        if (typeof window !== 'undefined') {
            let windowWidth = window.innerWidth
            let direction = windowWidth <= 1024 ? 'horizontal' : 'vertical'

            return direction
        }
    }

    const handleMouseOver = () => {
        cursorChangeHandler('test')
    }

    const handleMouseLeave = () => {
        cursorChangeHandler('default')
    }

    return (
        <>
            <div className="relative z-10 py-20 pt-28 overflow-hidden">
                <div className="grid grid-cols-20 auto-rows-auto lg:grid-cols-18 lg:max-w-screen-2xl mx-auto lg:max-w-screen">
                    <div className="col-span-full lg:col-start-2 lg:col-end-3 lg:z-20">
                        {vertResolver(locale) ? <div className="text-center mb-6 flex flex-row justify-center items-center lg:block">{block.title.split('').map(letter => <h2 className="uppercase text-darks1 font-display text-6xl lg:text-5xl lg:text-left">{letter === " " ? <>&nbsp;</> : letter}</h2>)}</div> : <h2 className="text-center uppercase text-darks1 font-display text-6xl mb-6 lg:text-left md:text-7xl lg:top-36 lg:mb-0 lg:vertical-rl lg:transform lg:rotate-180 lg:whitespace-pre-wrap">
                            {block.title}
                        </h2>}
                    </div>

                    <TestCursor
                        className="w-11/12 mx-auto col-start-1 col-end-21 lg:z-20 lg:col-start-5 lg:col-end-16 md:max-w-2xl lg:max-w-none lg:w-full relative"
                        onMouseOver={handleMouseOver}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Swiper
                            modules={[Pagination, A11y, Controller, Autoplay, Navigation]}
                            slidesPerView={1}
                            direction={getDirection()}
                            grabCursor={false}
                            autoplay={{
                                delay: 4000,
                                disableOnInteraction: false
                            }}
                            navigation={{
                                nextEl: '#nextButton',
                                prevEl: '#prevButton'
                            }}
                            pagination={{
                                el: '.pagination',
                                clickable: true,
                                renderBullet: (index, className) => {
                                    return (
                                        '<div tabindex="' +
                                        index +
                                        '" class="border border-solid border-darks5 p-1 rounded-2xl lg:border-none md:p-0"><span class="' +
                                        className +
                                        ' bg-darks5 w-1 h-1 block rounded cursor-pointer transition-all !shadow-lg"></span></div>'
                                    )
                                },
                            }}
                            onResize={(swiper) => {
                                swiper.changeDirection(getDirection())
                            }}
                            observer={true}
                            observeParents={true}
                            controller={{ control: controlledSecondSwiper }}
                            onSlideChange={(swiper) => {
                                setSlide(swiper.activeIndex + 1)
                            }}
                            onSwiper={(swiper) => {
                                setControlledSwiper(swiper)
                                setSlide(swiper.activeIndex + 1)
                                setTotalSlides(swiper.slides.length)
                            }}
                            className="go-swiper clipped-img r-cursor"
                        >
                            {block.slides.map((slide, index) => {
                                const media = getImage(slide.slideMedia)

                                return (
                                    <SwiperSlide
                                        key={index}
                                        className="lg:flex content-center justify-center"
                                    >
                                        <GatsbyImage
                                            image={media}
                                            alt={slide.slideMedia.alt ? slide.slideMedia.alt : ''}
                                            className="h-full m-auto lg:w-auto lg:ml-0 lg:mr-auto"
                                            objectFit="cover"
                                        />
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </TestCursor>

                    <div className="col-span-full border-t border-b border-gray border-opacity-20 my-8 py-2 px-8 flex justify-between items-center lg:hidden">
                        <div className="font-display flex md:max-w-2xl md:m-auto lg:max-w-none w-full">
                            <span className="text-blue text-5xl">0{slide}</span>
                            <span className="text-darks5 text-2xl leading-none ml-1">
                                /0{totalSlides}
                            </span>
                        </div>
                    </div>

                    <div className="hidden lg:flex lg:justify-between lg:flex-col lg:z-10 lg:w-full lg:col-start-16 lg:col-end-17">
                        <div className="pagination w-auto flex flex-col gap-4 lg:p-2 lg:mr-0 lg:ml-auto border border-darks5" />
                        <div className="font-display lg:flex lg:flex-col gap-3">
                            <BsChevronUp
                                className="text-darks5 text-xl font-bold ml-auto transition-all cursor-pointer hover:scale-150"
                                id="prevButton"
                            />
                            <div className="font-display lg:flex lg:flex-col text-right items-end">
                                <span className="text-blue text-5xl">0{slide}</span>
                                <span className="text-darks5 text-2xl leading-none">
                                    /0{totalSlides}
                                </span>
                            </div>
                            <BsChevronDown
                                className="text-darks5 text-xl font-bold ml-auto transition-all cursor-pointer hover:scale-150"
                                id="nextButton"
                            />
                        </div>
                    </div>

                    <TestCursor className="w-11/12 mx-auto col-start-1 col-end-21 lg:z-20 lg:col-start-5 lg:col-end-16 md:max-w-2xl lg:max-w-none lg:w-full  lg:mt-10 cursor-pointer relative">
                        <Swiper
                            modules={[Controller]}
                            slidesPerView={1}
                            direction={getDirection()}
                            onSwiper={setControlledSecondSwiper}
                            controller={{ control: controlledSwiper }}
                            onResize={(swiper) => {
                                swiper.changeDirection(getDirection())
                            }}
                            className="go-swiper-text max-w-full md:max-w-2xl lg:max-w-none lg:z-20 r-text-cursor"
                        >
                            {block.slides.map((slide, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <div className="text-darks5 w-full m-auto">
                                            <h3 className="font-display uppercase mb-4 text-3xl">
                                                {slide.slideTitle}
                                            </h3>
                                            <ReactMarkdown children={slide.slideBody} className="markdown" />
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </TestCursor>
                </div>
                <div className="absolute w-full h-full top-8 left-0 z-[-10] hidden md:block">
                    <div className="relative w-full h-full z-[-10]">
                        <div className="absolute w-full top-1/4 h-16 border-t border-b hidden lg:block border-darks5 border-opacity-20">
                            <StaticImage
                                src="../../../assets/images/drips/drip_texture_03.png"
                                alt=""
                                objectFit="contain"
                                className="!absolute h-12 top-0 left-0 opacity-40"
                            />
                            <StaticImage
                                src="../../../assets/images/drips/drip_texture_01.png"
                                alt=""
                                objectFit="contain"
                                className="!absolute h-12 top-0 -right-8 opacity-40"
                            />
                            <div className="border-t border-l border-darks5 p-2 right-0 -top-12 w-12 h-12 absolute border-opacity-60">
                                <div className="relative w-full h-full">
                                    <div className="grid grid-cols-2 absolute top-0 left-0">
                                        <span className="block h-2 w-2 bg-gray" />
                                        <span className="block h-2 w-2" />
                                        <span className="block h-2 w-2" />
                                        <span className="block h-2 w-2 bg-gray" />
                                        <span className="block h-2 w-2 bg-gray" />
                                        <span className="block h-2 w-2" />
                                        <span className="block h-2 w-2" />
                                        <span className="block h-2 w-2 bg-gray" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="absolute top-[35%] h-6 w-12 left-[20%] hidden lg:block">
                            <span className="block rounded w-[26px] h-[4px] bg-darks5 mb-2" />
                            <span className="block rounded w-[26px] h-[10px] white-black-box opacity-80" />
                        </div>
                        <div className="absolute top-[35%] h-6 w-12 right-20 hidden lg:block">
                            <span className="block rounded w-[26px] h-[4px] bg-darks5 mb-2" />
                            <span className="block rounded w-[26px] h-[10px] white-black-box opacity-80" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhiteCarousel
