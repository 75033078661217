import React from "react";

const SystemRequirements = ({ block }) => {
  const {
    id,
    heading,
    leftList1,
    leftList2,
    rightList1,
    rightList2,
    subheading1,
    subheading2,
  } = block;
  return (
    <div
      id={id}
      className="bg-darks5 pb-14 pt-[5.5rem] w-full lg:pb-20 lg:pt-28 lg:col-start-8 lg:col-end-19 relative text-offwhite md:clipped-split"
    >
      <div className="max-w-2xl lg:max-w-xl w-full mx-auto grid grid-cols-20 lg:grid-cols-11">
        <h2 className="font-display uppercase text-5xl lg:text-6xl mb-6 lg:transform lg:rotate-180 col-start-2 col-end-20 lg:col-start-11 lg:col-end-12 lg:sideways-lr text-darks1">
          {heading}
        </h2>
        <div className="col-start-2 col-end-20 lg:row-start-1 lg:col-start-1 lg:col-end-9 relative z-10">
          <div className="border-t border-blue py-4 pb-8">
            <h3 className="font-display uppercase text-xl lg:text-2xl">
              {subheading1}
            </h3>

            <div className="grid grid-cols-12 mt-4 font-body">
              <table className="col-span-full">
                <tbody>
                  {JSON.parse(leftList1).map((item, index) => (
                    <tr valign="top" key={index}>
                      <td className="px-2 py-1 pl-0">{item}:</td>
                      <td className="px-2 py-1">{JSON.parse(rightList1)[index]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="border-t border-blue py-4 pb-8">
            <h3 className="font-display uppercase text-xl lg:text-2xl">
              {subheading2}
            </h3>

            <div className="grid grid-cols-12 mt-4 font-body relative">
              <table className="col-span-full">
                <tbody>
                  {JSON.parse(leftList2).map((item, index) => (
                    <tr valign="top" key={index}>
                      <td className="px-2 py-1 pl-0">{item}:</td>
                      <td className="px-2 py-1">{JSON.parse(rightList2)[index]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="absolute -top-6 -left-4">
                  <span className="block h-1 w-1 rounded bg-blue shadow-blue mb-1" />
                  <span className="block h-1 w-1 rounded bg-blue shadow-blue" />
                </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute w-full h-full z-0 top-0 left-0 hidden md:block">
        <div className="relative w-full h-full z-0">
          <div className="absolute bottom-8 left-8">
            <span className="block h-1 w-4 bg-blue shadow-blue" />
          </div>
          <div className="absolute bottom-8 right-16 xl:right-24 md:right-">
            <div className="flex flex-row flex-nowrap gap-1">
              <span className="block h-1 w-1 rounded bg-blue shadow-blue" />
              <span className="block h-1 w-1 rounded bg-blue shadow-blue" />
              <span className="block h-1 w-1 rounded bg-blue shadow-blue" />
            </div>
          </div>
          <div className="absolute right-16 top-[52%] xl:right-24">
            <div className="flex flex-col flex-nowrap gap-0.5">
              <span className="block h-0.5 w-3 rounded bg-blue shadow-blue" />
              <span className="block h-0.5 w-3 rounded bg-blue shadow-blue" />
              <span className="block h-0.5 w-3 rounded bg-blue shadow-blue" />
              <span className="block h-0.5 w-3 rounded bg-blue shadow-blue" />
            </div>
          </div>
          <div className="absolute top-14 right-16 xl:right-24">
            <div className="flex flex-row flex-nowrap gap-1">
              <span className="block w-1 h-0.5 shadow-blue bg-blue rounded" />
              <span className="block w-24 h-0.5 shadow-blue bg-blue rounded" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemRequirements;
