import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

import InnerBladeResolver from "../../InnerBladeResolve";

const DarkImage = ({ blade, stack, ...rest }) => {
  const block = blade.block[0];
  const background = blade.image;

  let notch = JSON.parse(blade.bottomNotch);

  const image = getImage(background);

  notch = notch && notch.length ? notch.toString().toLowerCase() : ''

  return (
    <section
      className={`relative -mt-8 md:-mt-6 lg:-mt-8 stacking-context-${stack} ${notch && `notch-${notch}`}`}
    >
      <InnerBladeResolver {...{ ...blade, ...rest }} />
      {image ? (
        <GatsbyImage
          image={image}
          alt={background ? background.alt : ''}
          className="!absolute top-0 z-[-10] h-full w-full filter brightness-50"
        />
      ) : null}
    </section>
  );
};

export default DarkImage;
