import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const MediaCategory = ({ category, click, keypress, selected, image }) => {
  return (
    <div
      className={`border m-w-[110px] lg:flex-1 relative text-center text-xl uppercase py-4 px-4 lg:px-1 font-display cursor-pointer group hover:border-yellow hover:opacity-100 hover:text-white transition-all flex items-center justify-center ${
        selected
          ? "border-transparent text-blue"
          : "border-blue text-gray opacity-60"
      }`}
      onClick={(e) => click(e)}
      onKeyPress={(e) => keypress(e)}
      data-name={category}
      role="button"
      tabIndex={0}
    >
      {category}
      {image ? (
        <GatsbyImage
          image={image}
          alt={category}
          className={`!absolute z-[-10] h-full w-full ${
            selected ? "opacity-100 brightness-[0.3]" : "opacity-0"
          }`}
          objectFit="cover"
        />
      ) : null}

      <div
        className={`absolute z-[-10] w-full h-full group-hover:hidden ${
          selected ? "block" : "hidden"
        }`}
      >
        <div className="relative w-full h-full z-[-10]">
          <span className="block absolute w-1 h-1 bg-blue shadow-blue rounded top-[-1.5px] left-[-1.5px]" />
          <span className="block absolute w-1 h-1 bg-blue shadow-blue rounded top-[-1.5px] right-[-1.5px]" />
          <span className="block absolute w-1 h-1 bg-blue shadow-blue rounded bottom-[-1.5px] left-[-1.5px]" />
          <span className="block absolute w-1 h-1 bg-blue shadow-blue rounded bottom-[-1.5px] right-[-1.5px]" />
        </div>
      </div>
    </div>
  );
};

export default MediaCategory;
