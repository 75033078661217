import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from 'gbimage-bridge';

import WhiteButton from '../../../Buttons/WhiteButton';

const Card = ({ backgroundImage, internalOrExternalLink, externalLink, text, pageLink: { pageSlug } }, ...props) => {
    const image = getImage(backgroundImage.gatsbyImageData);
    const background = convertToBgImage(image);
    return (
        <div className="md:col-span-7 md:first-of-type:col-start-3 md:child(2n):col-start-10 w-11/12 max-w-[400px] md:max-w-none mx-auto relative">
            <BackgroundImage Tag="div" {...background} style={{
                position: 'relative'
            }}>
                <div className={`md:min-h-[425px] lg:min-h-[450px] relative flex flex-col items-end justify-end text-white py-10 lg:pt-16 lg:pb-8 px-4 z-10`}>
                    <h3 className="text-5xl lg:text-6xl font-display uppercase relative text-left w-11/12 mx-auto">{text}</h3>

                    <WhiteButton {...{
                        text: 'Apply',
                        link: internalOrExternalLink ? externalLink : `/${pageSlug}`,
                        isExternal: internalOrExternalLink,
                        classes: 'mr-auto ml-4 mt-4'
                    }} />
                </div>
                <div className="w-full h-full top-0 left-0 bg-darks5 bg-opacity-60 absolute z-0" />

            </BackgroundImage>

            <div className="absolute top-0 left-0 grid grid-cols-2 grid-rows-2 gap-1">
                <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
                <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
                <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
                <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
            </div>

            <div className="absolute top-0 right-0 grid grid-cols-2 grid-rows-2 gap-1">
                <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
                <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
                <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
                <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
            </div>

            <div className="absolute bottom-0 left-0 grid grid-cols-2 grid-rows-2 gap-1">
                <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
                <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
                <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
                <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
            </div>

            <div className="absolute bottom-0 right-0 grid grid-cols-2 grid-rows-2 gap-1">
                <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
                <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
                <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
                <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
            </div>
        </div>
    )
}

export default Card;