import React from "react";

import InnerBladeResolver from "../../InnerBladeResolve";

const Customisation = ({ blade, stack, location }) => {
  const block = blade.block[0];

  return (
    <section className={`relative stacking-context-${stack}`} key={block.id}>
      <InnerBladeResolver {...blade} location={location} />
    </section>
  );
};

export default Customisation;
