import React from "react";
import InnerBladeResolver from "../../InnerBladeResolve";

const FullWidthSplit = ({ blade, stack, locale, location }) => {
  let notch =
    blade.bottomNotch && blade.bottomNotch.length
      ? JSON.parse(blade.bottomNotch).toString().toLowerCase()
      : "";

  return (
    <section
      className={`relative -mt-8 ${
        notch ? `notch-${notch}` : null
      } stacking-context-${stack} ${stack === 9 ? "pt-40" : null}`}
    >
      <div className="grid grid-cols-1 lg:grid-cols-18 z-20 relative m-auto">
        <InnerBladeResolver {...blade} locale={locale} location={location} />
      </div>
    </section>
  );
};

export default FullWidthSplit;
