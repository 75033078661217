import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, A11y, Controller, Navigation, Autoplay } from 'swiper'

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useMouseState } from '../../../context';

import ReactMarkdown from 'react-markdown';

import cx from 'classnames';
import 'swiper/css';
import './styles.scss';
import TestCursor from '../../Cursor/TestCursor';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';

import { useLightboxState } from '../../../context';
import { vertResolver } from '../../../utils/vertResolver';


const GallerySlide = ({ gallery, inertLargeImage, locale }) => {

    let firstItem;
    const galleryArr = [];

    gallery.map((image, index) => {
        if (index === 0) {
            return firstItem = getImage(image.gatsbyImageData);
        } else {
            galleryArr.push(image);
        }
    });

    const cols = galleryArr.length > 2 ? galleryArr.length > 4 ? 3 : 2 : 2;

    const { setLightboxImages, setLightboxOpen, setActiveLightboxImage, setDownloadAllowed } = useLightboxState();

    const handlePushMedia = (index) => {
        const newArr = gallery.flat();

        setActiveLightboxImage(index);
        setLightboxImages(newArr);
        setDownloadAllowed(false);
        setLightboxOpen(true);
    }

    return (
        <div className={`gallery-slide flex flex-row flex-nowrap gap-6 ${(galleryArr.length / 2) === 2 && 'justify-center'}`}>
            <div className={`${(galleryArr.length / 2) === 2 ? 'w-[45%]' : 'w-1/3'} ${inertLargeImage ? 'order-2' : 'order-1'} clipped-gallery-large`} onClick={() => handlePushMedia(0)}>
                <GatsbyImage image={firstItem} objectFit="cover" objectPosition="center" className="w-full h-full" />
            </div>

            <div className={cx(`grid gap-6 ${(galleryArr.length / 2) === 2 ? 'w-2/2' : 'w-2/3'} py-12 ${inertLargeImage ? 'order-1' : 'order-2'}`, {
                'grid-cols-3': cols === 3,
                'grid-cols-2': cols === 2
            })}>
                {galleryArr.map((image, index) => {
                    const img = getImage(image.gatsbyImageData);

                    return (
                        <div className={`gallery-item-${index} max-h-[275px] overflow-hidden flex clipped-gallery-square`} onClick={() => handlePushMedia(index + 1)}>
                            <GatsbyImage image={img} className="w-full max-h-[275px]" objectFit="cover" objectPosition="center" />
                        </div>
                    )
                })}
            </div>
        </div>
    )
};

const GalleryCarousel = ({ block, locale }) => {
    const [slide, setSlide] = useState(1);
    const [totalSlides, setTotalSlides] = useState(0);
    const [controlledSwiper, setControlledSwiper] = useState(null)

    const paginationRef = useRef(null);

    const { cursorChangeHandler } = useMouseState();

    const { setLightboxImages, setLightboxOpen, setActiveLightboxImage, setDownloadAllowed } = useLightboxState();

    const handlePushMedia = (index) => {
        const arr = block.slideGallery.map(({ gallery }) => {
            return gallery.map((item) => {
                return item;
            })
        }).flat();

        setActiveLightboxImage(index);
        setLightboxImages(arr);
        setDownloadAllowed(false);
        setLightboxOpen(true);
    }

    const getDirection = () => {
        if (typeof window !== 'undefined') {
            let windowWidth = window.innerWidth
            let direction = windowWidth <= 1024 ? 'horizontal' : 'vertical'

            return direction
        }
    }

    const handleMouseOver = () => {
        cursorChangeHandler('test')
    }

    const handleMouseLeave = () => {
        cursorChangeHandler('default')
    }

    return (
        <div className="relative z-10 py-20 pt-28 overflow-hidden">
            <div className="grid grid-cols-20 auto-rows-auto lg:grid-cols-18 lg:max-w-screen-2xl mx-auto lg:max-w-screen">
                <div className="col-span-full lg:col-start-2 lg:col-end-3 lg:z-20 flex items-center justify-center">
                    {vertResolver(locale) ? <div className="text-center flex flex-row justify-center items-center mb-6 lg:block">{block.verticalText.split('').map(letter => <h2 className="uppercase text-darks1 font-display text-6xl md:text-7xl lg:mb-0 xl:text-8xl">{letter === " " ? <>&nbsp;</> : letter}</h2>)}</div> : <h2 className="text-center uppercase text-darks1 font-display text-6xl md:text-7xl lg:mb-0 xl:text-8xl lg:vertical-rl lg:transform lg:rotate-180 lg:whitespace-pre-wrap">
                        {block.verticalText}
                    </h2>}
                </div>
                <TestCursor className="w-11/12 mx-auto col-start-1 col-end-21 lg:z-20 lg:col-start-3 lg:col-end-18 md:max-w-2xl lg:max-w-none lg:w-full relative hidden lg:block" onMouseOver={handleMouseOver}
                    onMouseLeave={handleMouseLeave}>
                    <Swiper modules={[Pagination, A11y, Controller, Navigation]}
                        slidesPerView={1}

                        direction={getDirection()}
                        navigation={{
                            nextEl: '#nextButton',
                            prevEl: '#prevButton'
                        }}
                        pagination={{
                            el: paginationRef.current,
                            clickable: true,
                            renderBullet: (index, className) => {
                                return (
                                    '<div tabindex="' +
                                    index +
                                    '" class="border border-solid border-darks5 p-1 rounded-full lg:border-none md:p-0"><span class="' +
                                    className +
                                    ' bg-darks5 w-1 h-1 block rounded cursor-pointer transition-all !shadow-lg"></span></div>'
                                )
                            }
                        }}
                        onResize={(swiper) => {
                            swiper.changeDirection(getDirection())
                        }}
                        observer={true}
                        observeParents={true}
                        onSlideChange={(swiper) => {
                            setSlide(swiper.activeIndex + 1)
                        }}
                        onSwiper={(swiper) => {
                            setControlledSwiper(swiper)
                            setSlide(swiper.activeIndex + 1)
                            setTotalSlides(swiper.slides.length)
                        }} className="max-h-[675px]">
                        {block.slideGallery && block.slideGallery.map((slide, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <GallerySlide {...slide} />
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </TestCursor>

                <div className="lg:hidden grid grid-cols-2 gap-3 w-full col-start-2 col-end-20">
                    {block.slideGallery.map(({ gallery }, index) => {
                        return gallery.map((item, index) => {
                            const image = getImage(item.gatsbyImageData);

                            return <GatsbyImage image={image} className="" onClick={() => handlePushMedia(index)} />
                        })
                    })}
                </div>

                <div className="hidden lg:flex lg:justify-between lg:items-center lg:flex-col lg:z-10 lg:w-full lg:col-start-18 lg:col-end-19">
                    <div className="pagination w-auto flex flex-col gap-2 lg:p-2 border" ref={paginationRef} />
                    <div className="font-display lg:flex lg:flex-col gap-3">
                        <BsChevronUp
                            className="text-darks5 text-xl font-bold ml-auto transition-all cursor-pointer hover:scale-150"
                            id="prevButton"
                        />
                        <div className="font-display lg:flex lg:flex-col text-right items-end">
                            <span className="text-blue text-5xl">0{slide}</span>
                            <span className="text-darks5 text-2xl leading-none">
                                /0{totalSlides}
                            </span>
                        </div>
                        <BsChevronDown
                            className="text-darks5 text-xl font-bold ml-auto transition-all cursor-pointer hover:scale-150"
                            id="nextButton"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GalleryCarousel;