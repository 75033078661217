import React, { useState, useRef } from "react";
import YellowButton from "../../Buttons/YellowButton";
import Checkbox from "../../Checkbox";
import { StaticImage } from "gatsby-plugin-image";

import { gsap } from "gsap";
import { CSSPlugin } from "gsap/CSSPlugin";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { Link } from "gatsby";
import ReactMarkdown from "react-markdown";

gsap.registerPlugin(CSSPlugin);

const Newsletter = ({
  block: {
    heading,
    body,
    emailInputLabel,
    emailInputPlaceholder,
    gdprLabel,
    consentLabel,
    ctaText,
    extraLegalLines,
    consentLink,
    thankYouMessage,
  },
  locale,
  location,
}) => {
  const [form, setForm] = useState({
    email: "",
    gdpr: false,
    consent: false,
  });

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);

  const emailValidation = (mail) => {
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(String(mail).toLowerCase());
  };

  const handleEmailChange = (event) => {
    const {
      currentTarget: { name, value },
    } = event;

    if (error !== false || "") setError(false);

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleGdpr = (val) => {
    setForm({
      ...form,
      gdpr: val,
    });
  };

  const handleCheckbox = (val) => {
    setForm({
      ...form,
      consent: val,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!emailValidation(form.email) && (!token || token === "")) return;
    const date = new Date();

    const source = location.pathname;
    const optTimestamp = date;
    const optTimestampStr = date.toLocaleString("en-US");
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const data = JSON.stringify({
      email: form.email,
      timezone: timezone,
      source: source,
      optTimestamp: optTimestamp,
      optTimestampStr: optTimestampStr,
      language: locale,
      hCaptchaResponse: token,
    });

    await fetch(
      "https://ch013ermf0.execute-api.eu-west-1.amazonaws.com/v1/email",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }
    )
      .then((res) => {
        setSuccess(true);
      })
      .catch((err) => {
        setError(err);
      });
  };

  return (
    <div className="py-24 md:pb-20 md:pt-28 relative">
      <div className="grid grid-cols-20 m-auto md:gap-6 lg:grid-cols-16 relative z-10 md:max-w-2xl lg:max-w-screen-2xl">
        <div className="col-start-2 col-end-20 md:relative lg:col-start-2 lg:col-end-6 mb-8 lg:mb-0">
          <h2 className="font-display uppercase text-darks1 text-5xl lg:text-6xl lg:whitespace-pre-wrap mb-6">
            {heading}
          </h2>
          {body ? <p className="font-body text-offwhite">{body}</p> : null}
        </div>
        <div className="bg-darks5 px-10 py-10 md:py-16 md:px-16 col-start-2 col-end-20 lg:col-start-7 lg:col-end-15 clipped-img lg:pr-20 flex items-center justify-center relative">
          {success ? (
            <div
              className={`font-display uppercase text-gray text-3xl lg:text-4xl text-center flex flex-col items-center justify-center h-full w-[80%] max-w-[400px] mx-auto absolute z-40 transition-all ${
                success ? "opacity-100" : "opacity-0"
              }`}
            >
              {thankYouMessage}
            </div>
          ) : null}
          <form
            onSubmit={handleSubmit}
            className={`flex flex-col flex-nowrap gap-6 max-w-xs mx-auto md:max-w-full h-full ${
              success
                ? "opacity-30 blur-md pointer-events-none"
                : "pointer-events-auto opacity-100 blur-0"
            }`}
          >
            <div className={`xl:grid xl:grid-cols-2 xl:gap-8`}>
              <label className="text-center text-white md:text-left font-body flex flex-col mb-4">
                {emailInputLabel}
                {error ? <span className="text-yellow">{error}</span> : null}
                <input
                  type="email"
                  name="email"
                  placeholder={`${emailInputPlaceholder}*`}
                  required
                  value={form.email}
                  onChange={handleEmailChange}
                  className="clipped-input-field mt-4 bg-darks3 py-5 px-5 appearance-none outline-none"
                />
              </label>
              <div className="lg:mt-10 order-2">
                <label
                  className="flex flex-row mb-4 text-gray text-xs"
                  htmlFor="gdpr"
                >
                  <div>
                    <Checkbox
                      checked={form.gdpr}
                      onChange={(val) => handleGdpr(val)}
                      required
                    />
                  </div>
                  <div>
                    <span className="text-red-600">*</span> {gdprLabel}
                  </div>
                </label>
              </div>
            </div>
            <div className="flex flex-col order-3 items-start xl:flex-row xl:flex-nowrap gap-6">
              <HCaptcha
                sitekey="f135a593-1761-4e4a-bc10-eaedc00a0346"
                onVerify={(token, ekey) => setToken(token, ekey)}
                ref={captchaRef}
                className=""
                theme="dark"
              />

              <div className="w-36 md:w-1/2">
                <YellowButton
                  text={ctaText}
                  type="submit"
                  disabled={form.email === "" || !form.gdpr || !token}
                />
              </div>
            </div>
            {consentLink && consentLabel ? (
              <div className="order-4 text-gray">
                {consentLabel}
                &nbsp;
                <Link
                  to={`${
                    consentLink.locale.toLowerCase() !== ("en" || undefined)
                      ? `/${consentLink.locale.toLowerCase()}/${
                          consentLink.slug
                        }`
                      : `/${consentLink.slug}`
                  }`}
                  className="text-blue underline"
                >
                  {consentLink.pageTitle}
                </Link>
                .
              </div>
            ) : (
              <div className="order-4 text-gray">{consentLabel}.</div>
            )}
            <ReactMarkdown
              children={extraLegalLines}
              className="legal-markdown order-5 text-gray"
            />
          </form>
        </div>
      </div>
      <div className="absolute w-full h-full top-0 left-0 z-0">
        <div className="relative w-full h-full">
          <div className="absolute w-full top-[65%] h-16 border-t border-b border-gray opacity-20" />
          <div className="absolute w-full bottom-[10%] left-[10%]">
            <StaticImage
              src="../../../assets/images/shapes/rectangle.png"
              alt="Rectangle"
              className="w-48"
              objectFit="contain"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
