import React, { useEffect, useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import 'swiper/css'
import CustomisationItem from '../../CustomisationItem'

const CustomisationBlock = ({ block }) => {
    const { title, backgroundImage, slides } = block
    const [categories, setCategories] = useState([])
    const [active, setActive] = useState(0)

    useEffect(() => {
        slides.map((slide) =>
            setCategories((categories) =>
                !categories?.includes(slide?.title)
                    ? [...categories, slide?.title]
                    : []
            )
        )
    }, [slides])

    const background = getImage(backgroundImage)

    return (
        <div className="relative bg-darks5">
            <div className="grid grid-cols-20 auto-rows-auto z-20 relative py-20 lg:grid-cols-18 lg:grid-rows-1 max-w-screen-2xl gap-y-6 lg:gap-0 w-11/12 mx-auto">
                <div className="col-start-1 col-end-21 flex flex-row flex-wrap gap-4 md:mb-20 row-start-1">
                    {categories &&
                        categories.map((category, index) => (
                            <div
                                className={`border text-center min-w-[150px] max-w-[150px] text-xl uppercase p-4 font-display hover:border-orange hover:opacity-100 transition-all hover:text-white flex items-center justify-center cursor-pointer ${
                                    active === index
                                        ? 'border-yellow text-blue'
                                        : 'border-blue text-gray opacity-60'
                                }`}
                                key={index}
                                onClick={() => setActive(index)}
                                onKeyPress={(e) => (e.key === "Enter") && setActive(index)}
                                role="tab"
                                tabIndex={0}
                            >
                                {category}
                            </div>
                        ))}
                </div>

                <div className="col-start-2 col-end-20 lg:col-start-2 lg:col-end-18">
                    {slides.map((slide, index) => (
                        <CustomisationItem
                            slide={slide}
                            index={index}
                            activeIndex={active}
                        />
                    ))}
                </div>

                <div className="customisation-title hidden lg:block lg:col-start-18 lg:col-end-19 lg:row-start-1">
                    <h2 className="font-display lg:text-8xl lg:transform lg:rotate-90 text-white uppercase">
                        {title}
                    </h2>
                </div>
            </div>

            <div className="absolute background z-0 grid grid-cols-18 h-full w-full top-0 left-0">
                <div className="col-span-full lg:col-start-1 lg:col-end-14 bg-darks5" />
                <GatsbyImage
                    image={background}
                    alt={backgroundImage.alt ? backgroundImage.alt : ''}
                    className="w-full h-full z-0 !hidden lg:!block lg:col-start-14 lg:col-end-19 filter brightness-50"
                />
            </div>
        </div>
    )
}

export default CustomisationBlock
