import React from 'react';
import { renderNodeRule, StructuredText } from 'react-datocms';

import { isList, isParagraph } from 'datocms-structured-text-utils';
import TopLevelDark from '../../../assets/svgs/icn_00_dark.svg';


const FullWidthStructuredTextColumns = ({ block: { title, subtitle, leftColumnStructuredText, rightColumnStructuredText } }) => {

    return (
        <div className="py-32 z-30 grid grid-cols-1 lg:grid-cols-18 w-11/12 lg:w-full mx-auto md:max-w-2xl lg:max-w-screen-xl">

            {(title || subtitle) && (
                <div className="lg:col-start-2 lg:col-end-17 mb-6">
                    <h2 className="font-display uppercase text-white text-5xl lg:text-6xl mb-6">{title}</h2>
                    {subtitle && <h3 className="max-w-[740px] font-display uppercase text-blue text-lg md:text-xl lg:text-2xl">{subtitle}</h3>}
                </div>
            )}

            {(leftColumnStructuredText || rightColumnStructuredText) && (
                <div className="grid grid-cols-1 md:grid-cols-2 text-offwhite lg:col-start-2 lg:col-end-17 md:gap-4">
                    <div className="md:my-4">
                        <StructuredText
                            data={leftColumnStructuredText}
                            customNodeRules={[
                                renderNodeRule(
                                    isParagraph,
                                    ({
                                        node,
                                        adapter: { renderNode },
                                        children,
                                        key,
                                    }) => {
                                        return renderNode(
                                            `p`,
                                            {
                                                key,
                                                className:
                                                    'font-body my-4',
                                            },
                                            children
                                        )
                                    }
                                ),
                                renderNodeRule(
                                    isList,
                                    ({ node, children, key }) => {
                                        return (
                                            <ul>
                                                {children.map((child, index) => (
                                                    <li className="flex flex-row flex-nowrap items-start gap-1 font-light font-body box-sizing" key={index}>
                                                        <div className="w-[20px] h-[20px] mt-[1.15rem]">
                                                            <TopLevelDark width={20} height={20} />
                                                        </div>
                                                        {child.props.children[0]}
                                                    </li>
                                                ))}
                                            </ul>
                                        )
                                    }
                                )
                            ]} />
                    </div>
                    <div className="md:my-4">
                        <StructuredText
                            data={rightColumnStructuredText}
                            customNodeRules={[
                                renderNodeRule(
                                    isParagraph,
                                    ({
                                        node,
                                        adapter: { renderNode },
                                        children,
                                        key,
                                    }) => {
                                        return renderNode(
                                            `p`,
                                            {
                                                key,
                                                className:
                                                    'font-body my-4',
                                            },
                                            children
                                        )
                                    }
                                ),
                                renderNodeRule(
                                    isList,
                                    ({ node, children, key }) => {
                                        return (
                                            <ul>
                                                {children.map((child, index) => (
                                                    <li className="flex flex-row flex-nowrap items-start gap-1 font-light font-body box-sizing" key={index}>
                                                        <div className="w-[20px] h-[20px] mt-[1.15rem]">
                                                            <TopLevelDark width={20} height={20} />
                                                        </div>
                                                        {child.props.children[0]}
                                                    </li>
                                                ))}
                                            </ul>
                                        )
                                    }
                                )
                            ]} />
                    </div>
                </div>
            )}
        </div>
    )
}

export default FullWidthStructuredTextColumns;