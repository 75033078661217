import { GatsbyImage, getImage } from "gatsby-plugin-image";

import React, { useState, useEffect, useRef } from 'react';
import { Link } from "gatsby";
import { useWindowDimension } from "../../hooks/useWindowDimensions";

import { gsap } from 'gsap';
import { CSSPlugin } from "gsap/CSSPlugin";

gsap.registerPlugin(CSSPlugin)

const FactionTab = ({ node: {
    id, factionName, subtitle, factionPassage, factionLink, factionIcon, factionBackground, locale, factionColor: { red, green, blue, alpha }
}, click, index, isOpen }, props) => {
    const backgroundRef = useRef(null);
    const overlay = useRef(null);
    const titleRef = useRef(null);
    const [reveal, setReveal] = useState(false);
    const [width] = useWindowDimension();

    const background = getImage(factionBackground);
    const logo = getImage(factionIcon);

    const tl = useRef(gsap.timeline().addLabel('start', 0).addLabel('open', 0.2).reverse());

    useEffect(() => {
        tl.current.to([titleRef.current], {
            opacity: 0,
            duration: 0.2,
        }, "start")

        tl.current.to([overlay.current], {
            y: 0,
            duration: 0.2,
        }, "start");

        tl.current.to([backgroundRef.current], {
            scale: 1.1,
        }, "open");
    }, []);

    useEffect(() => {
        tl.current.reversed(!reveal);
    }, [reveal]);

    const handleAnimation = (state, type) => {
        switch (type) {
            case 'mouse':
                if (width >= 1024) return setReveal(state);
                break;
            case 'click':
                if (width < 1024) return setReveal(state);
                break;
            case 'focus':
                setReveal(state);
                break;
            case 'blur':
                setReveal(state);
                break;
            case 'key':
                setReveal(state);
                break
            default:
                return null;
        }

        return;
    }

    return (
        <div
            className="lg:w-1/3 shadow-2xl relative overflow-hidden border-box"
            onMouseOver={() => handleAnimation(true, 'mouse')}
            onMouseLeave={() => handleAnimation(false, 'mouse')}
            onClick={() => handleAnimation(!reveal, 'click')}
            onKeyPress={(e) => (e.key === "Enter") && handleAnimation(!reveal, 'key')}
            onFocus={() => handleAnimation(true, 'focus')}
            onBlur={() => handleAnimation(false, 'blur')}
            role="tab"
            tabIndex={0}
        >
            <div className="p-8 pt-80 pr-80 lg:pr-20 h-full flex">
                {locale.toLowerCase() === ("ja-jp" || "zh-cn" || "ko-kr") ? factionName.split('').map(letter => <div ref={titleRef} className="opacity-100"><h1 className="uppercase font-display leading-none text-left text-7xl text-white">{letter === " " ? <>&nbsp;</> : letter}</h1></div>) : <h1 ref={titleRef} className={`transform rotate-180 vertical-rl uppercase font-display leading-none text-left text-7xl text-white opacity-100`}>{factionName}</h1>}
            </div>

            <div ref={overlay} className={`w-full h-full translate-y-full absolute p-10 bottom-0 left-0 flex items-center justify-between flex-col flex-nowrap text-dark2 overflow-hidden`} style={{
                background: `rgba(${red}, ${green}, ${blue}, 0.8)`,
            }}>
                <div className="flex items-center justify-center h-28">
                    <GatsbyImage image={logo} alt={`${logo.alt ? logo.alt : factionName} Logo`} className="w-48 z-20" objectFit="contain" />
                </div>
                <p className="text-center font-body">{factionPassage}</p>

                <Link to={`${locale.toLowerCase() !== ("en" || undefined) ? `/news-and-media/${locale.toLowerCase()}/${factionLink.slug}` : `/news-and-media/${factionLink.slug}`}`} className="text-center hover:text-blue cursor-pointer">Read more</Link>
            </div>

            <div className={`absolute top-0 left-0 w-3 h-3 z-10`}>
                <span className="block absolute w-1 h-1 rounded bg-blue shadow-blue top-0 left-0" />
                <span className="block absolute w-1 h-1 rounded bg-blue shadow-blue top-0 right-0" />
                <span className="block absolute w-1 h-1 rounded bg-blue shadow-blue bottom-0 right-0" />
                <span className="block absolute w-1 h-1 rounded bg-blue shadow-blue bottom-0 left-0" />
            </div>

            <div className={`absolute top-0 right-0 w-3 h-3 `}>
                <span className="block absolute w-1 h-1 rounded bg-blue shadow-blue top-0 left-0" />
                <span className="block absolute w-1 h-1 rounded bg-blue shadow-blue top-0 right-0" />
                <span className="block absolute w-1 h-1 rounded bg-blue shadow-blue bottom-0 right-0" />
                <span className="block absolute w-1 h-1 rounded bg-blue shadow-blue bottom-0 left-0" />
            </div>

            <div className={`absolute bottom-0 right-0 w-3 h-3`}>
                <span className="block absolute w-1 h-1 rounded bg-blue shadow-blue top-0 left-0" />
                <span className="block absolute w-1 h-1 rounded bg-blue shadow-blue top-0 right-0" />
                <span className="block absolute w-1 h-1 rounded bg-blue shadow-blue bottom-0 right-0" />
                <span className="block absolute w-1 h-1 rounded bg-blue shadow-blue bottom-0 left-0" />
            </div>

            <div className={`absolute bottom-0 left-0 w-3 h-3`}>
                <span className="block absolute w-1 h-1 rounded bg-blue shadow-blue top-0 left-0" />
                <span className="block absolute w-1 h-1 rounded bg-blue shadow-blue top-0 right-0" />
                <span className="block absolute w-1 h-1 rounded bg-blue shadow-blue bottom-0 right-0" />
                <span className="block absolute w-1 h-1 rounded bg-blue shadow-blue bottom-0 left-0" />
            </div>

            <div className="absolute w-full h-full top-0 z-[-10]" ref={backgroundRef}>
                <GatsbyImage image={background} alt={`${background.alt ? background.alt : factionName} Art`} className="w-full h-full" />
            </div>
        </div>
    )
};

export default React.memo(FactionTab);