import React from "react";
import BlogPosts from "../InnerBlades/BlogPosts";
import Factions from "../InnerBlades/Factions";
import FaqBlock from "../InnerBlades/FaqBlock";
import FeaturedPosts from "../InnerBlades/FeaturedPosts";
import GameOverview from "../InnerBlades/GameOverview";
import ImageText from "../InnerBlades/ImageText";
import ImageTextSideTitle from "../InnerBlades/ImageTextSideTitle";
import Introduction from "../InnerBlades/Introduction";
import LinkSplit from "../InnerBlades/LinkSplit";
import MediaDownloads from "../InnerBlades/MediaDownloads";
import Newsletter from "../InnerBlades/Newsletter";
import PartnerSignUp from "../InnerBlades/PartnerSignUp";
import Play from "../InnerBlades/Play";
import PressKit from "../InnerBlades/PressKit";
import ProgramPerks from "../InnerBlades/ProgramPerks";
import CustomisationBlock from "../InnerBlades/CustomisationBlock";
import SystemRequirements from "../InnerBlades/SystemRequirements";
import WhatsNew from "../InnerBlades/WhatsNew";
import WhiteCarousel from "../InnerBlades/WhiteCarousel";
import SupportBlock from "../InnerBlades/SupportBlock";
import TextBlock from "../InnerBlades/TextBlock";
import InformationalSplit from "../InnerBlades/InformationalSplit";
import FullWidthStructuredTextColumns from "../InnerBlades/FullWidthStructuredTextColumns";
import StructuredContentBlock from "../InnerBlades/StructuredContentBlock";
import GalleryCarousel from "../InnerBlades/GalleryCarousel";
import AllPartners from "../InnerBlades/AllPartners";
import AffliatesBlock from "../InnerBlades/AffiliatesBlock";
import CardBlock from "../InnerBlades/CardBlock";
import SingleAsset from "../InnerBlades/SIngleAsset";
import SeasonContent from "../InnerBlades/SeasonContent";
import { ApplicationFormBlock } from "../InnerBlades/ApplicationFormBlock";

const InnerBladeResolver = ({
  __typename,
  block,
  blog,
  enBlog,
  locale,
  location,
}) => {
  const Components = {
    DatoCmsIntroduction: Introduction,
    DatoCmsWhatsNew: WhatsNew,
    DatoCmsPlay: Play,
    DatoCmsSystemRequirement: SystemRequirements,
    DatoCmsFactionBlock: Factions,
    DatoCmsImageTextBlock: ImageText,
    DatoCmsGameOverview: GameOverview,
    DatoCmsLinkSplit: LinkSplit,
    DatoCmsCustomisationBlock: CustomisationBlock,
    DatoCmsNewsletter: Newsletter,
    DatoCmsMediaAndDownloadBlock: MediaDownloads,
    DatoCmsImageTextSideTitle: ImageTextSideTitle,
    DatoCmsProgramPerkBlock: ProgramPerks,
    DatoCmsPartnerSignup: PartnerSignUp,
    DatoCmsNewsBlock: BlogPosts,
    DatoCmsFeaturedPostBlock: FeaturedPosts,
    DatoCmsPressKitBlock: PressKit,
    DatoCmsWhiteCarousel: WhiteCarousel,
    DatoCmsFaqBlock: FaqBlock,
    DatoCmsSupportBlock: SupportBlock,
    DatoCmsTextBlock: TextBlock,
    DatoCmsInformationalSplit: InformationalSplit,
    DatoCmsFullWidthStructuredTextColumn: FullWidthStructuredTextColumns,
    DatoCmsStructuredContentBlock: StructuredContentBlock,
    DatoCmsGalleryCarousel: GalleryCarousel,
    DatoCmsAffiliatesBlock: AffliatesBlock,
    DatoCmsAllPartner: AllPartners,
    DatoCmsCardBlock: CardBlock,
    DatoCmsSingleAssetBlock: SingleAsset,
    DatoCmsSeasonContent: SeasonContent,
    DatoCmsApplicationForm: ApplicationFormBlock,
  };

  return block.map((b, index) => {
    if (Components[b.__typename]) {
      return React.createElement(Components[b.__typename], {
        key: index,
        index: index,
        blog: blog,
        container: __typename,
        enBlog: enBlog,
        block: b,
        locale: locale,
        location: location,
      });
    }

    return false;
  });
};

export default InnerBladeResolver;
