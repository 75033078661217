import React, { useState, useEffect, useRef } from 'react';

import { gsap } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';

import ReactMarkdown from 'react-markdown';

gsap.registerPlugin(CSSPlugin);

const FaqAccordians = ({ title, body, index, open, faqOpen }) => {
    const accordianRef = useRef(null);
    const [openAccordian, setOpenAccordian] = useState(false);

    const tl = useRef(gsap.timeline({ defaults: { ease: "power2.inOut`" } }).reverse());

    const handleAccordian = () => {
        setOpenAccordian(!openAccordian);
        open(index)
    }

    useEffect(() => {
        if (faqOpen !== index) setOpenAccordian(false);
    }, [faqOpen, index])

    useEffect(() => {
        tl.current.to([accordianRef.current.children[1]], {
            display: 'hidden',
            height: '0%',
            duration: 0.5,
            paused: true,
        })
    }, []);

    useEffect(() => {
        tl.current.reversed(!openAccordian)
    }, [openAccordian])


    return (
        <div 
            className="" 
            onClick={handleAccordian} 
            onKeyPress={(e) => (e.key === "Event") && handleAccordian()} 
            role="tab" 
            tabIndex={0} 
            ref={accordianRef}>
            <div className={`flex flex-row flex-nowrap items-center justify-between p-4 gap-6 hover:text-white hover:bg-blue transition-all cursor-pointer lg:pl-8 ${openAccordian && (index === faqOpen) ? 'bg-blue' : 'bg-white text-darks1'}`}>
                <h3 className="uppercase font-display text-2xl lg:text-3xl">{title}</h3>
                <div className="bg-offwhite p-4 text-xl md:text-2xl" >
                    {openAccordian && (index === faqOpen) ? (
                        <AiOutlineMinus className="text-darks5" />
                    ) : (
                        <AiOutlinePlus className="text-darks5" />
                    )}
                </div>
            </div>
            <div className={`p-6 ${openAccordian && (index === faqOpen) ? 'block' : 'hidden'} border-blue border-b border-l border-r text-darks5`}>
                <ReactMarkdown children={body} className="markdown" />
            </div>
        </div>
    )
}

export default FaqAccordians;