import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import React from "react";

const Introduction = ({ block }) => {
  const img = getImage(block.image);

  return (
    <div className="relative py-24 md:pb-20 md:pt-28 w-full h-full">
      <div className="grid grid-cols-20 m-auto md:max-w-2xl lg:max-w-screen-2xl lg:grid-cols-18 z-20 relative">
        <div className="col-start-2 col-end-20 lg:col-start-2 lg:col-end-8 lg:flex lg:justify-center lg:flex-col">
          <div className="pb-6">
            <h2 className="font-display uppercase text-darks2 text-5xl lg:text-6xl whitespace-pre-line">
              {block.heading}
            </h2>
          </div>
          <div className="wrapper clipped-img flex lg:hidden mb-6">
            <GatsbyImage image={img} alt={block.image.alt ? block.image.alt : ''} />
          </div>
          <div className="flex flex-row items-center gap-2">
            <span className="h-0.5 w-14 bg-darks5" />
            <div className="w-full relative">
              <span className="h-px w-full bg-gray block" />
              <StaticImage
                src="../../../assets/images/drips/drip_texture_03.png"
                alt=""
                objectFit="contain"
                className="!absolute h-8 right-6 top-0 opacity-50"
              />
            </div>
          </div>
          <div className="mt-8">
            <p className="font-body text-darks2 lg:text-lg">{block.body}</p>
          </div>
        </div>
        <div className="lg:col-start-9 lg:col-end-18 lg:h-[600px] hidden lg:block">
          <div className="wrapper clipped-img md:h-full flex">
            <GatsbyImage image={img} alt={block.image.alt ? block.image.alt : ''} />
          </div>
        </div>
      </div>

      <div className="absolute hidden lg:block w-full h-full top-0 left-0 z-10">
        <div className="h-12 w-full relative">
          <div className="absolute top-5 z-30 left-4 flex items-center gap-1">
            <span className="bg-darks3 rounded w-[3px] h-[3px] block" />
            <span className="bg-darks3 rounded h-[3px] w-[40px] block" />
          </div>
          <div className="absolute top-5 z-30 hero-notch-fill-right flex items-center gap-1">
            <span className="bg-darks3 rounded w-[3px] h-[3px] block" />
            <span className="bg-darks3 rounded h-[3px] w-[40px] block" />
          </div>
        </div>
        <div className="border-t border-darks2 h-full w-full relative overflow-y-hidden">
          <div className="border-b-2 border-l-2 border-r-2 border-darks2 w-20 h-14 absolute left-4 p-2">
            <div className="relative w-full h-full">
              <div className="grid grid-cols-2 absolute top-0 right-0">
                <span className="block h-2 w-2 bg-gray" />
                <span className="block h-2 w-2" />
                <span className="block h-2 w-2" />
                <span className="block h-2 w-2 bg-gray" />
                <span className="block h-2 w-2 bg-gray" />
                <span className="block h-2 w-2" />
                <span className="block h-2 w-2" />
                <span className="block h-2 w-2 bg-gray" />
              </div>
            </div>
          </div>
          <div className="w-24 h-6 absolute left-32 flex flex-nowrap flex-row items-end justify-between">
            <div className="">
              <span className="block rounded h-[3px] w-3 bg-darks3 mb-1.5" />
              <span className="block rounded h-[3px] w-3 bg-darks3" />
            </div>
            <div className="flex flex-col flex-nowrap gap-1 relative">
              <span className="w-[35px] h-[3px] rounded bg-darks3" />
              <span className="block h-[10px] w-[35px] rounded white-black-box absolute -bottom-4 opacity-60" />
            </div>
          </div>
          <div className="absolute h-6 w-full flex items-end justify-center">
            <span className="block w-[35px] h-[3px] rounded bg-darks3" />
            <span className="block h-[10px] w-[35px] rounded white-black-box absolute -bottom-4" />

          </div>
          <div className="absolute right-4 h-6 w-44 flex flex-row items-end justify-center flex-nowrap gap-4">
            <span className="block w-[35px] h-[3px] rounded bg-darks3" />
            <span className="block h-[10px] w-[26px] rounded white-black-box absolute -bottom-4 left-0 opacity-60" />

            <div className="relative w-full">
              <span className="block w-full h-[3px] rounded bg-darks3" />
              <StaticImage
                src="../../../assets/images/drips/drip_texture_04.png"
                alt=""
                objectFit="contain"
                className="!absolute h-12 right-0 opacity-50"
              />
            </div>
          </div>
          <StaticImage
            src="../../../assets/images/drips/drip_texture_03.png"
            alt=""
            objectFit="contain"
            className="!absolute md:h-11 -top-1 left-44 opacity-50"
          />
          <StaticImage
            src="../../../assets/images/drips/drip_texture_01.png"
            alt=""
            objectFit="contain"
            className="!absolute h-16 -top-1 md:left-72 opacity-50"
          />
        </div>
      </div>
    </div>
  );
};

export default Introduction;
