import React, { useEffect, useState, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PartnerCard from './PartnerCard'
import Dropdown from '../../Dropdown'
import YellowButton from '../../Buttons/YellowButton'

const getAllOptions = ({ partners }) => {
    let all = []
    let uniqueCodes = []

    partners?.map(({ node: { regions, languages } }) => {
        regions?.map(({ name: regionName, code: regionCode }) => {
            languages?.map(({ name: languageName, code: languageCode }, i) => {
                if (
                    uniqueCodes.indexOf(`${regionCode}-${languageCode}`) === -1
                ) {
                    uniqueCodes.push(`${regionCode}-${languageCode}`)
                    all.push([languageName, regionName])
                }
            })
        })
    })

    return all
}

const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index
}

const AllPartners = ({ block: { title, id } }) => {
    const {
        partners: { edges: partners },
        regions: { edges: regions },
    } = useStaticQuery(graphql`
        query TwitchPartners {
            partners: allDatoCmsPartner(filter: { locale: { eq: "en" } }) {
                edges {
                    node {
                        id
                        partnerName
                        regions {
                            name: regionName
                            code
                        }
                        languages {
                            name
                            code
                        }
                        socials {
                            socialUrl
                            icon {
                                gatsbyImageData(placeholder: BLURRED)
                                url
                            }
                        }
                        partnerLogo {
                            gatsbyImageData(placeholder: BLURRED)
                        }
                    }
                }
            }
            regions: allDatoCmsPartnerRegion(filter: { locale: { eq: "en" } }) {
                edges {
                    node {
                        regionName
                    }
                }
            }
        }
    `)

    const [language, setLanguage] = useState('All Languages')
    const [region, setRegion] = useState('All Regions')
    const [searchInput, setSearchInput] = useState('')
    const [showFirst, setShowFirst] = useState(15)

    const [allOptions, setAllOptions] = useState(getAllOptions({ partners }))

    const onlyValidLanguage = (value) => {
        return region !== 'All Regions'
            ? allOptions?.filter(
                  (option) => option?.[1] === region && option?.[0] === value
              )?.length > 0
            : true
    }

    const onlyValidRegion = (value) => {
        return language !== 'All Languages'
            ? allOptions?.filter(
                  (option) => option?.[0] === language && option?.[1] === value
              )?.length > 0
            : true
    }

    const validLanguages = allOptions
        ?.map((option) => option?.[0])
        ?.filter(onlyUnique)
        ?.filter(onlyValidLanguage)
    const validRegions = allOptions
        ?.map((option) => option?.[1])
        ?.filter(onlyUnique)
        ?.filter(onlyValidRegion)

    const _partners = partners?.filter(
        ({ node: { partnerName, regions, languages } }) => {
            let match = false

            languages?.map(({ name: languageName }) => {
                regions?.map(({ name: regionName }) => {
                    if (
                        (languageName === language ||
                            language === 'All Languages') &&
                        (regionName === region || region === 'All Regions')
                    ) {
                        match = true
                    }
                })
            })

            if (
                searchInput?.length > 0 &&
                !partnerName.toLowerCase().includes(searchInput.toLowerCase())
            )
                match = false

            return match
        }
    )

    const handleChange = (e) => {
        setSearchInput(e.target.value)
    }

    const handleRegionChange = (event) => {
        const value = event.currentTarget.getAttribute('data-name')
        setRegion(value)
    }

    const handleLanguageChange = (event) => {
        const value = event.currentTarget.getAttribute('data-name')
        setLanguage(value)
    }

    const handleLoadMore = () => {
        setShowFirst(showFirst + 9)
    }

    useEffect(() => {
        if (validRegions?.indexOf(region) === -1) setRegion('All Regions')
    }, [language])

    useEffect(() => {
        if (validLanguages?.indexOf(language) === -1)
            setLanguage('All Languages')
    }, [region])

    return (
        <div className="py-20 lg:pt-28 relative" key={id}>
            <div className="flex flex-col max-w-screen-xl mx-auto w-11/12 z-40">
                <div className="lg:h-full lg:relative mb-6 z-40 flex items-start md:items-center justify-between flex-col md:flex-row gap-4">
                    <h2 className="font-display text-5xl lg:text-7xl uppercase text-darks4">
                        {title}
                    </h2>

                    <div className="flex flex-col md:flex-row gap-4">
                        <input
                            type="text"
                            placeholder="Search..."
                            onChange={handleChange}
                            value={searchInput}
                            className="p-4"
                        />
                        <Dropdown
                            {...{
                                filters: ['All Regions', ...validRegions],
                                placeholder: 'Region',
                                classNames: {
                                    options: 'max-h-[350px]',
                                    option: 'text-xs',
                                },
                                click: handleRegionChange,
                                selected: region,
                            }}
                        />
                        <Dropdown
                            {...{
                                filters: ['All Languages', ...validLanguages],
                                placeholder: 'Language',
                                classNames: {
                                    options: 'max-h-[350px]',
                                    option: 'text-xs',
                                },
                                click: handleLanguageChange,
                                selected: language,
                            }}
                        />
                    </div>
                </div>
                {_partners.length ? (
                    <div className="grid cols-span-full grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6 mt-8 md:mt-12">
                        {_partners.length &&
                            _partners.map(({ node }, i) => {
                                return (
                                    <PartnerCard
                                        {...node}
                                        key={i}
                                        active={i < showFirst}
                                    />
                                )
                            })}
                    </div>
                ) : (
                    <div className={`my-4`}>
                        <h2 className="font-display text-center uppercase text-3xl text-darks4">
                            No Results Found
                        </h2>
                    </div>
                )}

                {_partners?.length > 0 && _partners?.length > showFirst ? (
                    <div className="mt-12 md:mt-20 text-center">
                        <div className="inline-block">
                            <YellowButton
                                click={handleLoadMore}
                                text={'Load more'}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default AllPartners
