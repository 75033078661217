import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

import { isList } from 'datocms-structured-text-utils';
import { renderNodeRule, StructuredText } from "react-datocms";

import TopLevelDark from '../../assets/svgs/icn_00_dark.svg';
import { vertResolver } from "../../utils/vertResolver";

const Perk = ({ index, backgroundImage, perkBody, subheading, title, locale }) => {
  const image = getImage(backgroundImage);

  return (
    <div
      key={index}
      className={`lg:flex lg:flex-row md:min-h-[425px] relative justify-between md:col-span-7 md:first-of-type:col-start-3 md:child(2n):col-start-10 w-11/12 max-w-[400px] md:max-w-none mx-auto`}
    >
      <div
        className={`font-display uppercase relative flex flex-row flex-nowrap items-end pb-6 pt-32 z-20 px-10`}
      >
        <div
          className={`w-min text-white`}
        >
          {vertResolver(locale) ? title.split('').map(letter => <h3 className="text-5xl lg:text-6xl">{letter === " " ? <>&nbsp;</> : letter}</h3>) : <h3 className="text-5xl lg:text-6xl vertical-rl transform rotate-180">{title}</h3>}
          {vertResolver(locale) ? subheading.split('').map(letter => <h4 className="text-lg m-0">{letter === " " ? <>&nbsp;</> : letter}</h4>) : <h4 className={`text-lg m-0 vertical-rl transform rotate-180`}>
            {subheading}
          </h4>}
        </div>
        <StructuredText
          data={perkBody}
          customNodeRules={[
            renderNodeRule(
              isList,
              ({ node, children, key }) => {
                return (
                  <ul>
                    {children.map((child, index) => (
                      <li className="flex flex-row flex-nowrap items-start gap-1 font-light box-sizing text-blue font-display" key={index}>
                        <div className="w-[20px] h-[20px] mt-0.5">
                          <TopLevelDark width={20} height={20} />
                        </div>
                        {child.props.children[0]}
                      </li>
                    ))}
                  </ul>
                )
              }
            )
          ]} />
      </div>
      <GatsbyImage
        image={image}
        className={`!absolute w-full h-full bottom-0 left-0 z-0`}
        alt="background"
      />

      <div className="absolute top-0 left-0 grid grid-cols-2 grid-rows-2 gap-1">
        <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
        <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
        <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
        <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
      </div>

      <div className="absolute top-0 right-0 grid grid-cols-2 grid-rows-2 gap-1">
        <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
        <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
        <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
        <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
      </div>

      <div className="absolute bottom-0 left-0 grid grid-cols-2 grid-rows-2 gap-1">
        <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
        <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
        <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
        <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
      </div>

      <div className="absolute bottom-0 right-0 grid grid-cols-2 grid-rows-2 gap-1">
        <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
        <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
        <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
        <span className="block bg-blue rounded-full w-1 h-1 shadow-blue" />
      </div>
    </div>
  );
};

export default Perk;
