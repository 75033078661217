import React, { useEffect, useState } from 'react';

const SupportBlock = ({ block }) => {
    const { title, subtitle, body, forms } = block;

    const [active, setActive] = useState(0);

    useEffect(() => {
        forms.map((form) => {
            let scriptUrl = form.liveAgentUrl;
            let node = document.createElement('script');
            node.src = scriptUrl;
            node.id = 'la_x2s6df8d';
            node.type = 'text/javascript';
            node.async = true;
            node.onload = function (e) {
                window.LiveAgent.createForm(form.formCode, document.getElementById(`${form.formName.replace(/\s+/g, '-').toLowerCase()}Container`));
            }
            return document.getElementsByTagName('head')[0].appendChild(node);
        });
    }, [forms]);

    const setActiveForm = (formIndex) => {
        setActive(formIndex);
    }

    return (
        <div className="py-28 grid grid-cols-18 max-w-screen-2xl mx-auto gap-y-8">
            <div className="col-start-3 col-end-16 row-start-1 grid grid-cols-18">
                <div className='col-span-full lg:col-start-1 lg:col-end-9 flex flex-col mb-6 lg:mb-0'>
                    {title ? <h2 className="font-display uppercase text-gray text-6xl lg:text-7xl mb-8">{title}</h2> : null}
                    {subtitle ? <h3 className="font-display uppercase text-gray mb-4 text-2xl">{subtitle}</h3> : null}
                    {body ? <p className="text-gray font-body">{body}</p> : null}
                </div>
                <div className="col-span-full lg:col-start-11 lg:col-end-19 flex flex-col gap-3" role="tablist" >
                    {forms.map((form, index) => (
                        <div
                            key={index}
                            index={index}
                            className={`border cursor-pointer hover:border-orange p-4 w-full ${active === index ? 'border-blue' : 'border-gray'}`}
                            onClick={() => setActiveForm(index)}
                            onKeyPress={(e) => (e.key === "Event") && setActiveForm(index)}
                            role="tab"
                            tabIndex={0}
                        >
                            <h2 className={`uppercase font-display text-4xl lg:text-5xl ${active === index ? 'text-blue' : 'text-gray'}`}>{form.formName}</h2>
                            <p className={`font-display text-gray uppercase ${active === index ? 'block' : 'hidden'}`}>{form.formDescription}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="col-start-2 col-end-17 lg:col-start-3 lg:col-end-16 row-start-2 clipped-img bg-darks5 p-8" id="liveAgentWrapper">
                {forms.map((form, index) => {
                    return (
                        <div
                            key={index}
                            index={index}
                            className={`${active === index ? 'relative' : 'absolute left-[200%]'}`}>
                            <div
                                id={`${form.formName.replace(/\s+/g, '-').toLowerCase()}Container`} />
                        </div>
                    )

                })}
            </div>

        </div>

    )
}

export default SupportBlock;