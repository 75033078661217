import React, { Fragment } from 'react'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'

const PartnerContainer = ({ social, className, children }) =>
    social?.socialUrl ? (
        <a
            href={social?.socialUrl}
            target="_blank"
            rel="nofollow noopener noreferrer"
            className={className}
        >
            {children}
        </a>
    ) : (
        <div className={className}>{children}</div>
    )

const PartnerCard = ({
    partnerName,
    id,
    partnerLogo,
    regions = [],
    languages = [],
    socials = [],
    active = true,
}) => {
    const logo = getImage(partnerLogo)
    const social = socials?.[0]

    return (
        <PartnerContainer
            {...{ social }}
            className={`${
                active ? 'flex' : 'hidden'
            } flex-row flex-nowrap items-center group hover:scale-105 transition-transform`}
        >
            <div className="max-w-[80px] max-h-[80px] w-[80px] h-[80px] rounded-full bg-darks4 overflow-hidden flex-shrink-0 z-20 shadow-md group-hover:shadow-xl">
                <GatsbyImage
                    image={logo}
                    objectFit="cover"
                    objectPosition="center"
                />
            </div>
            <div className="-ml-5 w-full shadow-md group-hover:shadow-xl overflow-hidden">
                <div className="bg-darks4 bg-opacity-50 text-white uppercase font-display relative text-2xl pl-8 pr-4">
                    <span className="flex flex-row flex-nowrap items-center justify-between">
                        <h3 className="z-10 text-lg relative truncate">
                            {partnerName}
                        </h3>
                        <span className="flex items-center">
                            {social?.icon ? (
                                <span
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="flex items-center justify-center align-middle"
                                >
                                    {social?.icon?.gatsbyImageData ? (
                                        <GatsbyImage
                                            image={getImage(
                                                social?.icon?.gatsbyImageData
                                            )}
                                            width={18}
                                            height={18}
                                        />
                                    ) : (
                                        <img
                                            src={social?.icon?.url}
                                            width={18}
                                            height={18}
                                        />
                                    )}
                                </span>
                            ) : null}
                        </span>
                    </span>
                    <span className="absolute top-0 left-0 block w-[60%] group-hover:w-[80%] transition-all h-full bg-darks4 z-0 clipped-partner-bg-top" />
                </div>
                <div className="bg-orange bg-opacity-50 pl-8 pr-6 relative">
                    <h4 className="flex items-center flex-row gap-2 font-body text-lg z-10 relative">
                        {regions.map(({ code: regionCode }, regionIndex) => (
                            <span className="flex items-center justify-start -m-2">
                                <span
                                    key={regionIndex}
                                    className="p-2 relative"
                                >
                                    {languages.map(
                                        (
                                            { code: languageCode },
                                            languageIndex
                                        ) => (
                                            <Fragment key={languageIndex}>
                                                {languageCode}
                                                {languageIndex <
                                                languages.length - 1 ? (
                                                    <span>/</span>
                                                ) : null}
                                            </Fragment>
                                        )
                                    )}{' '}
                                    - {regionCode}
                                </span>
                            </span>
                        ))}
                    </h4>
                    <span className="absolute top-0 left-0 block w-[60%] group-hover:w-[80%] transition-all h-full bg-orange z-0 clipped-partner-bg-bottom" />
                </div>
            </div>
        </PartnerContainer>
    )
}

export default PartnerCard
