import React from 'react';

const TextBlock = ({ block }) => {

    return (
        <div className="py-40">
            <div className="flex items-center justify-center flex-col flex-nowrap m-auto max-w-screen-2xl w-11/12 lg:w-full text-white uppercase font-display text-center">
                <h2 className="text-6xl lg:text-8xl mb-4">{block.heading}</h2>
                <p className="text-2xl">{block.body}</p>
            </div>
        </div>
    )
}

export default TextBlock;