import { StaticImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import { BsChevronDown } from 'react-icons/bs'
import BlogItem from '../../BlogItem'
import YellowButton from '../../Buttons/YellowButton'

const BlogPosts = ({ block, blog, enBlog }) => {
    const [filter, setFilter] = useState('Latest News')
    const [dropdown, setDropdown] = useState()
    const [posts, setPosts] = useState(2)
    const [categories, setCategories] = useState(['Latest News']);

    const chunkyArray = (array, chunk_size) => {
        let index = 0
        let arrLength = array.length
        var tempArr = []

        for (index = 0; index < arrLength; index += chunk_size) {
            let myChunk = array.slice(index, index + chunk_size)
            tempArr.push(myChunk)
        }

        return tempArr
    }

    const blogArr = blog?.edges?.map((edge, index) => {
        if (edge.node) {
            const { node } = edge
            const category = JSON.parse(node.category)

            category?.map((cat) => {
                if (!categories.includes(cat)) {
                    return setCategories([...categories, cat])
                }
                return null;
            })

            return node
        }

        return null;
    })

    const renderPosts = () => {
        let filtered = ''
        let arrayChunks
        let backupArray = enBlog;
        let newArr = blogArr.flat().map((post, index) => {
            if (!post.heading) {
                return post = backupArray.edges[index].node
            }

            return post;
        })

        if (filter) {
            filtered = newArr.filter((post) => {
                return post?.category ? JSON.parse(post?.category).includes(filter) : null;
            })
        }

        arrayChunks = filtered.length
            ? chunkyArray(filtered.slice(0, posts), 2)
            : chunkyArray(newArr.slice(0, posts), 2)

        return arrayChunks.map((chunk) => {
            return chunk.map((post, index) => (
                <BlogItem key={index} {...post} />
            ))
        })
    }

    const increasePosts = () => {
        setPosts(posts + 2)
    }

    const handleFilter = (event) => {
        setFilter(event.target.dataset.name)
        setDropdown(false)
    }

    const handleDropdown = () => {
        setDropdown(!dropdown)
    }

    return (
        <div key={block.id} className="py-28 relative">
            <div className="grid grid-cols-20 lg:grid-cols-18 grid-rows-auto m-auto md:max-w-2xl lg:max-w-screen-2xl">
                <div className="row-start-1 mb-12 col-start-2 col-end-20 md:flex md:flex-row md:flex-nowrap md:justify-between z-20 relative w-full lg:col-end-18">
                    <h2 className="font-display text-darks1 text-5xl md:text-6xl uppercase mb-6 md:mb-0">
                        {block.title}
                    </h2>
                    <div className="w-52 lg:w-72 relative z-50 font-body">
                        <div
                            className={`${dropdown ? 'bg-offwhite' : 'bg-white'
                                } p-4 w-full flex flex-row flex-nowrap justify-between items-center cursor-pointer`}
                            onClick={handleDropdown}
                            onKeyPress={(e) => (e.key === "Enter") && handleDropdown()}
                            role="tablist"
                            tabIndex={0}
                        >
                            {filter}
                            <div
                                className={`w-min p-2 ${dropdown ? 'bg-darks5' : 'bg-gray'
                                    } transition-bg`}
                            >
                                <BsChevronDown
                                    className={`${dropdown
                                        ? 'text-blue rotate-180'
                                        : 'text-darks5 rotate-0'
                                        } transition-all`}
                                />
                            </div>
                        </div>
                        <div
                            className={`${dropdown ? 'flex' : 'hidden'
                                } flex-col absolute w-full`}
                        >
                            {categories.map((category, index) => (
                                <div
                                    className="p-4 bg-darks3 w-full text-gray hover:bg-offwhite hover:text-darks3 transition-all cursor-pointer"
                                    data-name={category}
                                    onClick={handleFilter}
                                    onKeyPress={(e) => (e.key === "Enter") && handleFilter()}
                                    role="tab"
                                    tabIndex={0}
                                    key={index}
                                >
                                    {category}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="row-start-2 col-start-2 col-end-20 lg:col-start-2 lg:col-end-18 mb-12 z-10 relative">
                    <div className="md:grid gap-12 lg:grid-cols-18">
                        {renderPosts()}
                    </div>
                </div>
                {blogArr?.length > posts ? (
                    <div className="row-start-3 col-span-full mx-auto w-56 z-20">
                        <YellowButton
                            click={() => increasePosts()}
                            text={block.ctaText ? block.ctaText : 'Load more'}
                        />
                    </div>
                ) : null}
            </div>

            <div className="absolute w-full h-1/2 top-1/2 border-t border-darks2 z-0 overflow-y-hidden hidden">
                <div className="relative w-full h-full">
                    <StaticImage
                        src="../../../assets/images/drips/drip_texture_03.png"
                        alt="Grunge Drip"
                        objectFit="contain"
                        className="absolute h-16 opacity-50 -top-1 left-6"
                    />
                    <div className="absolute top-4 right-4">
                        <div className="relative">
                            <span className="block w-24 h-[4px] rounded bg-darks3" />
                            <StaticImage
                                src="../../../assets/images/drips/drip_texture_04.png"
                                objectFit="contain"
                                className="absolute h-16 opacity-50"
                                alt="Grunge Drip"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="absolute w-full h-full top-8 left-0 hidden md:block z-0">
                <div className="relative w-full h-full">
                    <div className="absolute top-[35%] w-full border-t border-darks5 opacity-80" />
                    <div className="absolute top-[37%] w-16 h-1 rounded bg-darks5 right-2">
                        <div className="relative">
                            <StaticImage
                                src="../../../assets/images/drips/drip_texture_04.png"
                                objectFit="contain"
                                className="absolute h-16 opacity-50 -top-2 z-[-10]"
                                alt="Grunge Drip"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogPosts
