import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { AiOutlinePlus } from 'react-icons/ai'

const ItemDownload = ({ category, image, video, click }) => {
    return (
        <div
            className="w-full max-h-[300px] md:col-span-9 lg:col-span-6 lg:h-[275px] cursor-pointer flex items-center justify-center relative group lg:hover:transform lg:hover:scale-105 bg-darks5 transition-all z-20 overflow-hidden"
            data-cat={category}
            onClick={() => click()}
            onKeyPress={(e) => (e.key === "Enter") && click()}
            role="tab"
            tabIndex={0}
        >
            <div className="absolute z-30 border border-blue p-5 rounded-full bg-darks5 bg-opacity-70">
                <AiOutlinePlus className="text-white text-md" />
            </div>
            {image ? (
                <GatsbyImage
                    image={image}
                    alt="some alt"
                    objectFit="cover"
                    className="w-inherit h-full group-hover:opacity-40 group-hover:transition-opacity z-20"
                />
            ) : (
                <img
                    src={video.thumbnailUrl}
                    alt="Video preview"
                    className="w-inherit h-full group-hover:opacity-40 group-hover:transition-opacity z-20"
                />
            )}
        </div>
    )
}

export default ItemDownload
