import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import cx from 'classnames';

const SeasonItem = ({ grade, tag, image, number }) => {
    const img = getImage(image.gatsbyImageData);
    const bg = convertToBgImage(img);

    return (
        <div className="overflow-hidden group cursor-pointer drop-shadow-lg">
            <div className="clipped-season-item">
                <BackgroundImage tag="Div" {...bg} preserveStackingContext className="transform group-hover:scale-110 transition-transform duration-500">
                    <div className={cx('relative pt-48 md:pt-56 text-center overflow-hidden')}>
                        <div className="bg-gray bg-opacity-50 relative w-full py-2 z-10 group-hover:opacity-0 transition-opacity duration-300">
                            <span className="block font-display text-white uppercase text-2xl">{tag}</span>
                        </div>
                        <div className="w-full py-2 z-10 relative group-hover:opacity-0 transition-opacity duration-300">
                            <span className="block font-display text-white uppercase text-2xl">{number < 10 ? `0${number}` : number}</span>
                        </div>
                        <div className={cx('bg-gradient-to-t to-transparent absolute w-full h-1/2 bottom-0 left-0 z-0 group-hover:-bottom-full transition-all duration-300', {
                            'from-offwhite opacity-60': grade === 'uncommon',
                            'from-uncommon opacity-30': grade === 'common',
                            'from-rare opacity-30': grade === 'rare',
                            'from-epic opacity-30': grade === 'epic',
                            'from-orange opacity-30': grade === 'legendary',
                        })} />
                    </div>
                </BackgroundImage>
            </div>
        </div>
    )
}

const SeasonContent = ({ key, index, block: {
    seasonPassItems = [], title
}, locale }) => {
    return (
        <div className="w-full py-32 gree md:pt-28 relative" id={index}>
            <div className="flex items-center justify-center flex-col lg:gap-6 max-w-screen-xl mx-auto w-11/12 z-40">
                <div className="pb-6 text-center">
                    <h2 className="font-display uppercase text-darks2 whitespace-pre-line text-6xl lg:text-7xl">{title}</h2>
                </div>

                {seasonPassItems && (
                    <div className="grid grid-cols-2 w-full md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
                        {seasonPassItems.map(({ collectionGrade, collectionName, collectionTag, contentImage, collectionNumber }) => <SeasonItem {...{
                            grade: collectionGrade,
                            tag: collectionTag,
                            image: contentImage,
                            number: collectionNumber
                        }} />)}
                    </div>
                )
                }
            </div>
        </div>
    )
}

export default SeasonContent;