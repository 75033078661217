import React, { useState } from 'react'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { Pagination, A11y, Controller, Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import CustomReactPlayer from '../../CustomReactPlayer'

import 'swiper/css'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { useMouseState } from '../../../context'
import TestCursor from '../../Cursor/TestCursor'
import { vertResolver } from '../../../utils/vertResolver'

const GameOverview = ({ block, locale }) => {
    const [slide, setSlide] = useState(1)
    const [totalSlides, setTotalSlides] = useState(0)
    const [controlledSwiper, setControlledSwiper] = useState(null)
    const [controlledSecondSwiper, setControlledSecondSwiper] = useState(null)

    const { cursorChangeHandler } = useMouseState()

    const handleMouseOver = () => {
        cursorChangeHandler('test')
    }

    const handleMouseLeave = () => {
        cursorChangeHandler('default')
    }

    const slidesData = []
    block.slides.map((slide) => {
        const body = slide.slideBody
        const media = getImage(slide.slideMedia)
        const video = slide.slideMedia.video
        const title = slide.slideTitle

        const slideObject = {
            body,
            media,
            video,
            title,
        }

        return slidesData.push(slideObject)
    });

    const getDirection = () => {
        if (typeof window !== 'undefined') {
            let windowWidth = window.innerWidth
            let direction = windowWidth <= 1024 ? 'horizontal' : 'vertical'

            return direction
        }
    }

    return (
        <div className="relative z-10 py-20 pt-28 overflow-hidden">
            <div className="grid grid-cols-20 auto-rows-auto lg:grid-cols-18 lg:max-w-screen-2xl mx-auto lg:max-w-screen">
                <div className="col-span-full lg:col-start-2 lg:col-end-3 lg:z-20">
                    {vertResolver(locale) ? <div className="text-center lg:text-left uppercase text-darks1 font-display text-6xl items-center justify-center mb-6 flex flex-row lg:block">{block.heading.split('').map(letter =><h2 className="block">{letter === " " ? <>&nbsp;</> : letter}</h2>)}</div> : <h2 className="text-center lg:text-left uppercase text-darks1 font-display text-6xl mb-6 md:text-7xl lg:text-8xl lg:top-36 lg:mb-0 lg:whitespace-pre-wrap lg:vertical-rl lg:transform lg:rotate-180">
                        {block.heading}
                    </h2>}
                </div>

                <TestCursor
                    className="w-11/12 mx-auto col-start-1 col-end-21 lg:z-20 lg:col-start-5 lg:col-end-16 md:max-w-2xl lg:max-w-none lg:w-full relative"
                    onMouseOver={handleMouseOver}
                    onMouseLeave={handleMouseLeave}
                >
                    <Swiper
                        modules={[
                            Pagination,
                            A11y,
                            Controller,
                            Autoplay,
                            Navigation,
                        ]}
                        slidesPerView={1}
                        direction={getDirection()}
                        grabCursor={false}
                        autoplay={{
                            delay: 6000,
                            disableOnInteraction: true,
                        }}
                        navigation={{
                            nextEl: '#nextButton',
                            prevEl: '#prevButton',
                        }}
                        pagination={{
                            el: '.pagination',
                            clickable: true,
                            renderBullet: (index, className) => {
                                return (
                                    '<div tabindex="' +
                                    index +
                                    '" class="border border-solid border-blue p-1 rounded-2xl lg:border-none md:p-0"><span class="' +
                                    className +
                                    ' bg-blue w-1 h-1 block rounded cursor-pointer transition-all"></span></div>'
                                )
                            },
                        }}
                        onResize={(swiper) => {
                            swiper.changeDirection(getDirection())
                        }}
                        observer={true}
                        observeParents={true}
                        controller={{ control: controlledSecondSwiper }}
                        onSlideChange={(swiper) => {
                            setSlide(swiper.activeIndex + 1)
                        }}
                        onSwiper={(swiper) => {
                            setControlledSwiper(swiper)
                            setSlide(swiper.activeIndex + 1)
                            setTotalSlides(swiper.slides.length)
                        }}
                        className="go-swiper clipped-img cursor-pointer"
                    >
                        {slidesData.map((slide, index) => {
                            return (
                                <SwiperSlide
                                    key={index}
                                    className="lg:flex content-center justify-center"
                                >
                                    {slide.media ? (
                                        <GatsbyImage
                                            image={slide.media}
                                            alt={slide.title}
                                            className="h-full m-auto lg:w-full lg:ml-0 lg:mr-auto"
                                            objectFit="cover"
                                        />
                                    ) : null}
                                    {slide.video ? (
                                        <CustomReactPlayer
                                            url={slide.video.mp4Url}
                                            fallback={slide.video.thumbnailUrl}
                                        />
                                    ) : null}
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>

                    <div className={`absolute top-0 right-0 z-50 w-2 h-2`}>
                        <span className="block absolute w-0.5 h-0.5 rounded bg-blue shadow-blue top-0 left-0" />
                        <span className="block absolute w-0.5 h-0.5 rounded bg-blue shadow-blue top-0 right-0" />
                        <span className="block absolute w-0.5 h-0.5 rounded bg-blue shadow-blue bottom-0 right-0" />
                        <span className="block absolute w-0.5 h-0.5 rounded bg-blue shadow-blue bottom-0 left-0" />
                    </div>

                    <div className={`absolute bottom-0 left-0 z-50 w-2 h-2`}>
                        <span className="block absolute w-0.5 h-0.5 rounded bg-blue shadow-blue top-0 left-0" />
                        <span className="block absolute w-0.5 h-0.5 rounded bg-blue shadow-blue top-0 right-0" />
                        <span className="block absolute w-0.5 h-0.5 rounded bg-blue shadow-blue bottom-0 right-0" />
                        <span className="block absolute w-0.5 h-0.5 rounded bg-blue shadow-blue bottom-0 left-0" />
                    </div>

                    <div className="absolute w-full h-full z-[-10] top-0 left-0 hidden lg:block">
                        <div className="absolute -left-8 bottom-14 flex flex-col gap-1">
                            <span className="bg-blue shadow-blue w-3 h-1 rounded" />
                            <span className="bg-blue shadow-blue w-3 h-1 rounded" />
                            <span className="bg-blue shadow-blue w-3 h-1 rounded" />
                            <span className="bg-blue shadow-blue w-3 h-1 rounded" />
                        </div>
                    </div>
                </TestCursor>

                <div className="col-span-full border-t border-b border-gray border-opacity-20 my-8 py-2 px-8 flex justify-between items-center lg:hidden">
                    <div className="font-display flex md:max-w-2xl md:m-auto lg:max-w-none w-full">
                        <span className="text-blue text-5xl">0{slide}</span>
                        <span className="text-gray text-2xl leading-none ml-1">
                            /0{totalSlides}
                        </span>
                    </div>
                </div>

                <div className="hidden lg:flex lg:justify-between lg:flex-col lg:z-10 lg:w-full lg:col-start-16 lg:col-end-17">
                    <div className="pagination w-auto flex flex-col gap-4 lg:bg-darks5 lg:p-2 lg:mr-0 lg:ml-auto" />
                    <div className="flex flex-col flex-nowrap gap-4 ml-auto"></div>
                    <div className="font-display lg:flex lg:flex-col gap-3">
                        <BsChevronUp
                            className="text-blue text-xl font-bold ml-auto transition-all cursor-pointer hover:scale-150"
                            id="prevButton"
                        />
                        <div className="lg:flex lg:flex-col text-right items-end">
                            <span className="text-blue text-5xl">0{slide}</span>
                            <span className="text-gray text-2xl leading-none">
                                /0{totalSlides}
                            </span>
                        </div>
                        <BsChevronDown
                            className="text-blue text-xl font-bold ml-auto transition-all cursor-pointer hover:scale-150"
                            id="nextButton"
                        />
                    </div>
                </div>

                <TestCursor className="col-start-2 col-end-19 max-w-full md:max-w-2xl lg:max-w-none lg:mt-10 cursor-pointer lg:col-start-5 lg:col-end-15 lg:z-20 relative mx-auto">
                    <Swiper
                        modules={[Controller]}
                        slidesPerView={1}
                        direction={getDirection()}
                        onSwiper={setControlledSecondSwiper}
                        controller={{ control: controlledSwiper }}
                        onResize={(swiper) => {
                            swiper.changeDirection(getDirection())
                        }}
                        className="go-swiper-text"
                    >
                        {slidesData.map((slide, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <div className="text-offwhite w-11/12 m-auto lg:w-full">
                                        <h3 className="font-display uppercase mb-4 text-3xl">
                                            {slide.title}
                                        </h3>
                                        <p className="font-body text-gray">
                                            {slide.body}
                                        </p>
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </TestCursor>
            </div>
            <div className="absolute w-full h-full top-8 left-0 z-[-10] hidden lg:block">
                <div className="relative w-full h-full z-[-10]">
                    <div className="absolute -top-3 left-[12rem] flex flex-row justify-between items-center w-52">
                        <span className="w-4 h-2 bg-blue shadow-blue block" />
                        <div className="flex flex-row flex-nowrap gap-2.5">
                            <span className="block rounded w-[3px] h-[3px] bg-blue shadow-blue" />
                            <span className="block rounded w-[3px] h-[3px] bg-blue shadow-blue" />
                            <span className="block rounded w-[3px] h-[3px] bg-blue shadow-blue" />
                        </div>
                    </div>
                    <div className="absolute w-full top-[18%] h-20 border-t border-b hidden lg:block border-gray opacity-20" />
                    <div className="absolute top-2 left-[35%] flex flex-row flex-nowrap gap-1">
                        <span className="block h-1 w-1 rounded bg-blue shadow-blue" />
                        <span className="block h-1 w-1 rounded bg-blue shadow-blue" />
                        <span className="block h-1 w-1 rounded bg-blue shadow-blue" />
                    </div>
                    <div className="absolute bottom-12 left-[14%] flex flex-row flex-nowrap gap-1">
                        <span className="block h-0.5 w-1 rounded bg-blue shadow-blue" />
                        <span className="block h-0.5 w-20 rounded bg-blue shadow-blue" />
                    </div>
                    <div className="absolute bottom-[22%] right-0 h-[350px] w-24 border-t border-b border-l border-blue">
                        <div className="relative w-full h-full">
                            <div className="absolute top-1/2 left-2 flex flex-col flex-nowrap gap-1">
                                <span className="block h-1 w-1 rounded bg-blue shadow-blue" />
                                <span className="block h-1 w-1 rounded bg-blue shadow-blue" />
                            </div>
                            <div className="absolute bottom-12 -left-4 flex flex-col flex-nowrap gap-1">
                                <span className="block h-0.5 w-2 rounded bg-blue shadow-blue" />
                                <span className="block h-0.5 w-2 rounded bg-blue shadow-blue" />
                            </div>
                        </div>
                    </div>

                    <div className="absolute left-20 top-[28%] flex flex-col gap-1">
                        <span className="block h-1 w-1 rounded bg-blue shadow-blue" />
                        <span className="block h-1 w-1 rounded bg-blue shadow-blue" />
                    </div>

                    <div className="absolute left-28 top-[24%] flex flex-col gap-1">
                        <span className="block h-1 w-2 rounded bg-blue shadow-blue" />
                        <span className="block h-1 w-2 rounded bg-blue shadow-blue" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GameOverview
