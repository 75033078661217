export const vertResolver = (locale) => {
    switch (locale.toLowerCase()) {
        case "ko-kr":
            return true;
        case "zh-cn":
            return true;
        case "ja-jp":
            return true;
        default:
            return false;
    }
}