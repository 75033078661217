import React from 'react';
import { BsPlayFill } from 'react-icons/bs';

const CustomPlayButton = ({ click }) => {

    return (
        <div 
            className="absolute" 
            onClick={() => click()} 
            onKeyPress={(e) => (e.key === "Enter") && click()} 
            role="button" 
            tabIndex={0}>
            <button className="p-1 bg-darks5 bg-opacity-60 text-white relative">
                <div className="p-6 border-blue border-[0.5px] border-solid">
                    <BsPlayFill className="w-[25px] h-[25px] max-w-[25px]" />
                </div>
                <span className="w-1 h-1 bg-white rounded top-0 left-0 absolute block" />
                <span className="w-1 h-1 bg-white rounded bottom-0 right-0 absolute block" />
            </button>
        </div>
    )
}
export default CustomPlayButton;