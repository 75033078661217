import React from "react";

import { useStaticQuery, graphql } from "gatsby";
import FactionTab from "../../FactionTab";
import { StaticImage } from "gatsby-plugin-image";

const Factions = ({ block, locale }) => {
  const { id, title, body } = block;

  const { factions } = useStaticQuery(graphql`
    query FactionsQuery {
      factions: allDatoCmsFaction {
        edges {
          node {
            id
            factionName
            subtitle
            locale
            factionPassage
            factionColor {
              red
              green
              blue
              alpha
            }
            factionIcon {
              gatsbyImageData(placeholder: BLURRED)
            }
            factionNameImage {
              gatsbyImageData(placeholder: BLURRED)
            }
            factionBackground {
              gatsbyImageData(placeholder: BLURRED)
            }
            factionLink {
              slug
            }
          }
        }
      }
    }
  `);

  const localeFaction = factions.edges.filter(({ node: { locale: _locale } }) => _locale === locale);

  return (
    <div className="w-full py-32 md:pt-28 relative" id={id}>
      <div className="flex items-center justify-center flex-col lg:gap-6 max-w-screen-xl mx-auto w-11/12 z-40">
        <div className="pb-6 text-center max-w-lg">
          <h2 className="font-display uppercase text-darks2 whitespace-pre-line text-6xl md:text-7xl lg:text-8xl">{title}</h2>
          <p className="mt-6 font-body">{body}</p>
        </div>

        <div className="flex flex-col flex-nowrap gap-4 lg:flex-row items-stretch w-full z-10 max-w-lg lg:max-w-6xl">
          {localeFaction.map((node, index) => {
            return <FactionTab key={index} index={index} {...node} />
          })}
        </div>
      </div>

      <div className="absolute w-full h-full top-8 left-0 z-[-10] hidden md:block">
        <div className="relative w-full h-full z-[-10]">

          <div className="border-b border-l border-t border-darks2 w-20 h-48 absolute right-0 top-10 p-2">
            <div className="relative w-full h-full">
              <div className="grid grid-cols-2 absolute top-4 left-2">
                <span className="block h-2 w-2 bg-gray" />
                <span className="block h-2 w-2" />
                <span className="block h-2 w-2" />
                <span className="block h-2 w-2 bg-gray" />
                <span className="block h-2 w-2 bg-gray" />
                <span className="block h-2 w-2" />
                <span className="block h-2 w-2" />
                <span className="block h-2 w-2 bg-gray" />
              </div>
              <StaticImage
                src="../../../assets/images/drips/drip_texture_02.png"
                alt="Grunge drip"
                objectFit="contain"
                className="!absolute h-12 bottom-[-3.15rem] right-2 opacity-50"
              />
            </div>
          </div>

          <div className="absolute top-[55%] w-full left-0 border-t border-b border-darks1 border-opacity-40 h-16">

            <div className="relative w-full h-full">
              <div className="grid grid-cols-4 absolute top-[35%] left-1/4 w-8 h-4">
                <span className="block bg-transparent" />
                <span className="block bg-darks1 bg-opacity-20" />
                <span className="block bg-transparent" />
                <span className="block bg-darks1 bg-opacity-20" />
                <span className="block bg-darks1 bg-opacity-20" />
                <span className="block bg-transparent" />
                <span className="block bg-darks1 bg-opacity-20" />
                <span className="block bg-transparent" />
              </div>

              <div className="flex flex-row flex-nowrap gap-1.5 absolute top-2 left-[5%]">
                <span className="block bg-darks5 rounded-lg w-1 h-1.5" />
                <span className="block bg-darks5 rounded-lg w-1 h-1.5" />
                <span className="block bg-darks5 rounded-lg w-1 h-1.5" />
              </div>

              <div className="flex flex-col flex-nowrap gap-1 absolute -top-6 left-[2%]">
                <span className="block bg-darks5 rounded w-3 h-1" />
                <span className="block bg-darks5 rounded w-3 h-1" />
              </div>

              <div className="flex flex-col flex-nowrap gap-1 absolute -bottom-6 left-[2%]">
                <span className="w-[35px] h-[4px] rounded-xl bg-darks3" />
                <span className="block h-[10px] w-[35px] rounded white-black-box absolute -bottom-5 opacity-60" />
              </div>

              <div className="absolute -bottom-6 left-[22%]">
                <span className="block h-[4px] w-36 bg-darks5 rounded-lg" />
                <StaticImage
                  src="../../../assets/images/drips/drip_texture_03.png"
                  alt="Grunge drip"
                  objectFit="contain"
                  className="!absolute h-10 -right-4 opacity-50"
                />
              </div>

              <div className="flex flex-col flex-nowrap gap-1 absolute -top-10 right-4">
                <span className="w-[35px] h-[4px] rounded-xl bg-darks3" />
                <span className="block h-[10px] w-[35px] rounded white-black-box absolute -bottom-5 opacity-60" />
              </div>

              <StaticImage
                src="../../../assets/images/drips/drip_texture_01.png"
                alt="Grunge drip"
                objectFit="contain"
                className="!absolute h-16 right-2 opacity-50"
              />
            </div>

          </div>

        </div>
      </div>
    </div>
  );
};

export default Factions;
