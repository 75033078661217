import React from "react";
import classnames from "classnames";
import { useStaticQuery, graphql } from "gatsby";

import InnerBladeResolver from "../../InnerBladeResolve";

const DarkTextured = ({ blade, stack, enBlog, locale, location }) => {
  const { background } = useStaticQuery(graphql`
    query getDarkTexturedBackground {
      background: file(
        relativePath: { eq: "images/textures/texture-img-dark.png" }
      ) {
        publicURL
      }
    }
  `);

  const block = blade.block[0];
  let notch = JSON.parse(blade.bottomNotch);

  notch = notch && notch.length ? notch.toString().toLowerCase() : "";
  return (
    <section
      className={classnames(
        `relative -mt-8 bg-top bg-repeat-all ${
          notch ? `notch-${notch}` : null
        } stacking-context-${stack} ${stack === 9 ? "pt-20" : null}`
      )}
      key={block.id}
      style={{ backgroundImage: `url(${background?.publicURL})` }}
    >
      <InnerBladeResolver
        {...blade}
        enBlog={enBlog}
        locale={locale}
        location={location}
      />
    </section>
  );
};

export default DarkTextured;
