import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import BlogItem from "../../BlogItem";
import YellowButton from "../../Buttons/YellowButton";

const WhatsNew = ({ block, blog, locale }) => {
  const data = useStaticQuery(graphql`
    query BlogQuery {
      whatsNew: allDatoCmsBlog(
        limit: 2, 
        filter: { locale: { eq: "en" } }
        sort: {order: DESC, fields: meta___firstPublishedAt}
      ) {
        edges {
          node {
            heading
            excerpt
            articleImage {
              gatsbyImageData(placeholder: BLURRED)
              alt
            }
            category
            meta {
              firstPublishedAt(formatString: "DD-MM-YYYY")
            }
            slug
            locale
          }
        }
      }
    }
  `);

  data.whatsNew.edges.map((bPost, index) => {
    return blog.edges.map((post) => {
      if (post.node.slug === bPost.node.slug) return data.whatsNew.edges[index] = post;
      return null;
    })
  })

  return (
    <div key={block.id} className="py-24 md:pt-28 relative">
      <div className="grid grid-cols-20 lg:grid-cols-18 grid-rows-auto m-auto md:max-w-2xl lg:max-w-screen-2xl">
        <div className="row-start-1 mb-12 col-start-2 col-end-20 md:flex md:flex-row md:flex-nowrap md:justify-between md:items-center z-20 relative w-full lg:col-end-18">
          <h2 className="font-display text-darks1 text-5xl md:text-6xl uppercase mb-4 md:mb-0">
            {block.heading}
          </h2>

          <div className="hidden md:block">
            <YellowButton link={`${locale.toLowerCase() !== ('en' || undefined) ? `/${locale.toLowerCase()}/news-and-media` : `/news-and-media`}`} text={block.ctaText ? block.ctaText : "More news"} />
          </div>

        </div>
        <div className="row-start-2 col-start-2 col-end-20 lg:col-start-2 lg:col-end-18 mb-12 z-20 relative">
          <div className="md:grid gap-12 lg:grid-cols-18">
            {data.whatsNew.edges.map((blog, index) => {
              return <BlogItem key={index} {...blog.node} />;
            })}
          </div>
        </div>
        <div className="row-start-3 col-span-full mx-auto w-48 md:hidden">
          <YellowButton link="/news-and-media" isExternal={false} text={block.ctaText ? block.ctaText : "More news"} />
        </div>
      </div>

      <div className="absolute w-full h-1/2 top-1/2 border-t border-darks2 z-0 overflow-y-hidden hidden">
        <div className="relative w-full h-full">
          <StaticImage
            src="../../../assets/images/drips/drip_texture_03.png"
            alt=""
            objectFit="contain"
            className="absolute h-16 opacity-50 -top-1 left-6"
          />
          <div className="absolute top-4 right-4">
            <div className="relative">
              <span className="block w-24 h-[4px] rounded bg-darks3" />
              <StaticImage
                src="../../../assets/images/drips/drip_texture_04.png"
                objectFit="contain"
                className="absolute h-16 opacity-50"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsNew;
