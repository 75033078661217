import React, { useState, useRef } from 'react';
import { Link } from 'gatsby';
import HCaptcha from '@hcaptcha/react-hcaptcha';

import WhiteButton from '../Buttons/WhiteButton';

const Toggle = ({ active = false, toggleActive, name = "", label = "" }) => (
    <div
        onClick={typeof toggleActive === 'function' && toggleActive}
        onKeyPress={(e) => typeof toggleActive === 'function' && (e.key === "Enter") && toggleActive()}
        role="tab"
        tabIndex={0}
        className={`toggle relative cursor-pointer ${active ? 'active' : 'null'}`}
        label={label}
        id={name} />
);

const EpicBetaForm = ({ locale, location }) => {
    const [email, setEmail] = useState("")
    const [played, setPlayed] = useState(false);
    const [confirmAge, setConfirmAge] = useState(false);
    const [news, setNews] = useState(false);

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false)

    const [token, setToken] = useState(null);
    const captchaRef = useRef(null);

    const handleChange = (event) => setEmail(event.target.value);

    const emailValidation = (mail) => {
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regexp.test(String(mail).toLowerCase());
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!confirmAge || !emailValidation(email)) {
            return;
        }

        const date = new Date();

        const source = location.pathname;
        const optTimestamp = date;
        const optTimestampStr = date.toLocaleString('en-US');
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;



        const data = JSON.stringify({
            'email': email,
            'marketing': news ? 'yes' : 'no',
            'age': confirmAge ? 'yes' : 'no',
            'played': played ? 'yes' : 'no',
            'nda': true,
            'source': source,
            'timezone': timezone,
            'optTimestamp': optTimestamp,
            'optTimestampStr': optTimestampStr,
            'language': locale,
            'hCaptchaResponse': token
        });

        await fetch('https://5plj8mdk00.execute-api.eu-west-1.amazonaws.com/v1/email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: data,
        }).then(res => res).then(res => setSuccess(true), setError(false)).catch(error => console.log('[error]', setError(true)));

    }

    return (
        <div className="p-8 md:p-10 md:pr-12 md:pl-16 lg:px-16 lg:py-12 md:clipped-img bg-gray text-darks5 col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12">
            {(!success && error) && (
                <div className="my-4">
                    <h4 className="font-display uppercase text-4xl my-4 text-red-500">Error!</h4>
                    <p className="font-body">An error occurred. Please try again later.</p>
                </div>
            )}

            {(!success && !error) && (
                <form onSubmit={handleSubmit} className="font-body">
                    <div className="flex flex-col items-start">
                        <label htmlFor='email'><sup className="text-red-500">*</sup> Email Address</label>
                        <input type="email" name="email" placeholder="johndoe@gmail.com" className="py-2 pr-2 pl-4" value={email} onChange={handleChange} />
                    </div>

                    <div className="my-6 flex flex-col gap-2 font-bold">
                        <div className="flex flex-row gap-4">
                            <div className="w-[42px]">
                                <Toggle active={played} name="played" label="I have played The Cycle Before." toggleActive={() => setPlayed(!played)} />
                            </div>
                            <p htmlFor="played">I have played The Cycle Before.</p>
                        </div>
                        <div className="flex flex-row gap-4">
                            <div className="w-[42px]">
                                <Toggle active={confirmAge} label="* By checking this box I confirm that I am over 16 years of age." name="age" toggleActive={() => setConfirmAge(!confirmAge)} />
                            </div>
                            <p htmlFor="age"><sup className="text-red-500">*</sup> By checking this box I confirm that I am over 16 years of age.</p>
                        </div>
                        <div className="flex flex-row gap-4">
                            <div className="w-[42px]">
                                <Toggle active={news} label="By checking this box I agree to receive more information about the cycle (news, events and sales)." name="news" toggleActive={() => setNews(!news)} />
                            </div>
                            <p htmlFor="news">By checking this box I agree to receive more information about the cycle (news, events and sales).</p>
                        </div>
                    </div>

                    <HCaptcha sitekey="f135a593-1761-4e4a-bc10-eaedc00a0346" onVerify={setToken} ref={captchaRef} />

                    <div className="w-[200px] my-6">
                        <WhiteButton type="submit" text="Register" />
                    </div>


                    <p>Fields marked <sup className="text-red-500">*</sup> are required</p>
                </form>
            )}

            {(success && !error) && (
                <div className="my-4">
                    <h4 className="font-display uppercase text-4xl my-4">Subscribed!</h4>
                    <p className="font-body">You will receive a confirmation email shortly. Please verify your email.
                    </p>
                </div>
            )}

            <p className="font-body">Copyright @ 2022 YAGER Development GmbH. All rights reserved.</p>

            <Link to={`/privacy-policy`} className="text-darks5 font-body hover:text-orange mt-2">Privacy Policy</Link>
        </div>
    )
}

export default EpicBetaForm;