import React, { useEffect } from "react";

import { graphql } from "gatsby";
import BladeResolver from "@components/BladeResolver";
import { useMenuState } from "@context";
import LightboxGallery from "@components/LightboxGallery";

import Layout from "@hoc/Layout";
import Seo from "@components/SEO";

const Page = ({ pageContext, data, location }) => {
  const { setMenuOpen } = useMenuState();

  useEffect(() => {
    setMenuOpen(false);
    return () => setMenuOpen(false)
  }, [location, setMenuOpen]);

  return (
    <Layout locale={pageContext.locale}>
      <Seo
        meta={data?.page?.seoMetaTags?.tags}
        title={data?.page?.metadata?.title}
      />
      <LightboxGallery />
      <BladeResolver
        {...data.page}
        enBlog={data.enBlog}
        blog={data.blog}
        locale={pageContext.locale}
        location={location}
      />
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query PageQuery($id: String, $locale: String) {
    page: datoCmsPage(id: { eq: $id }, locale: { eq: $locale }) {
      id
      pageTitle
      pageSlug
      metadata {
        description
        title
      }
      seoMetaTags {
        tags
      }
      blades {
        ... on DatoCmsLightImage {
          ...LightImage
        }
        ... on DatoCmsLightTextured {
          ...LightTextured
        }
        ... on DatoCmsDarkImage {
          ...DarkImage
        }
        ... on DatoCmsDarkTextured {
          ...DarkTextured
        }
        ... on DatoCmsSplitBlade {
          ...Split
        }
        ... on DatoCmsCustomisation {
          ...Customisation
        }
        ... on DatoCmsFullWidthSplit {
          ...FullWidth
        }
      }
    }
    blog: allDatoCmsBlog(
      filter: { locale: { eq: $locale } }
      sort: { order: DESC, fields: meta___firstPublishedAt }
    ) {
      edges {
        node {
          heading
          excerpt
          articleImage {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
          category
          locale
          meta {
            firstPublishedAt(formatString: "DD-MM-YYYY")
          }
          slug
        }
      }
    }
    enBlog: allDatoCmsBlog(
      filter: { locale: { eq: "en" } }
      sort: { order: DESC, fields: meta___firstPublishedAt }
    ) {
      edges {
        node {
          id
          heading
          excerpt
          articleImage {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
          category
          locale
          meta {
            firstPublishedAt(formatString: "DD-MM-YYYY")
          }
          slug
        }
      }
    }
  }
`;
