import React from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { renderNodeRule, StructuredText } from 'react-datocms';
import { isList } from 'datocms-structured-text-utils'

import ReactMarkdown from 'react-markdown';

import BackgroundImage from 'gatsby-background-image';
import { convertToBgImage } from 'gbimage-bridge';

import TopLevelDark from '../../../assets/svgs/icn_00_dark.svg';

const InformationalSplit = ({ block }) => {
    const { streamingLogo, backgroundImage, topCopy, paths } = block;

    const background = getImage(backgroundImage.gatsbyImageData);
    const bg = convertToBgImage(background);
    const logo = getImage(streamingLogo);

    return (
        <div className="flex flex-col lg:col-span-9 relative">
            <BackgroundImage
                tag="div"
                {...bg}
                style={{
                    backgroundSize: 'cover',
                    height: '100%',
                    position: 'relative',
                }}
            >
                <div className="absolute w-full z-[-10] h-full bg-darks5 bg-opacity-70" />

                <div className="py-20 p-4 z-20 max-w-xl mx-auto">
                    <div className="text-white flex flex-col items-center justify-center text-center max-w-[275px] mx-auto font-body">
                        <GatsbyImage image={logo} alt={streamingLogo.alt ? streamingLogo.alt : ''} className="w-28 mb-4" />
                        <ReactMarkdown children={topCopy} />
                    </div>

                    <span className="bg-blue w-full h-[1px] block my-6" />

                    <div className="flex flex-row gap-4">
                        {paths.map((path, i) => {
                            return (
                                <div className="lg:w-1/2" key={i}>
                                    <h3 className="uppercase text-blue text-2xl font-display">{path.title}</h3>
                                    <StructuredText
                                        data={path.pathList}
                                        customNodeRules={[
                                            renderNodeRule(
                                                isList,
                                                ({ node, children, key }) => {
                                                    return (
                                                        <ul className="my-4">
                                                            {children.map((children, index) => (
                                                                <div className="flex flex-row flex-nowrap items-start gap-1 text-white font-light font-body text-md leading-none mb-4" key={index}>
                                                                    <div className="w-[20px] h-[20px] -mt-0.5">
                                                                        <TopLevelDark width={20} height={20} />
                                                                    </div>
                                                                    {children.props.children[0]}
                                                                </div>
                                                            ))}
                                                        </ul>
                                                    )
                                                }
                                            )
                                        ]}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>

            </BackgroundImage>
        </div>
    )
}

export default InformationalSplit;