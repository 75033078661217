import React from "react";
import { Link } from "gatsby";

const MainButton = ({ text, role, type, link, click }) => {
  let renderButton;

  switch (type) {
    case "link":
      renderButton = (
        <Link
          className="yellow-button relative flex flex-col items-center justify-center cursor-pointer w-full h-[65px]"
          to={link}
        >
          <span className="absolute text-lg md:text-xl font-body uppercase text-darks4 tracking-wider z-10 px-4 lg:px-6">
            {text}
          </span>
          <div className="absolute overflow-hidden" />
        </Link>
      );
      break;
    default:
      // Button
      renderButton = (
        <button
          role={role}
          className={`yellow-button relative flex flex-col items-center justify-center cursor-pointer h-[65px] w-full`}
          onClick={click}
        >
          <span className="text-lg font-body md:text-xl uppercase text-darks4 tracking-wider z-10 px-4 lg:px-6">
            {text}
          </span>
          <div className="absolute overflow-hidden" />
        </button>
      );
      break;
  }

  return <>{renderButton}</>;
};

export default MainButton;