import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { BsPlay, BsPlayFill } from 'react-icons/bs';
import ReactPlayer from 'react-player';
import { useLightboxState } from '../../../context';

const SingleAsset = ({ block: { asset, pushToLightbox } }) => {
    const { setLightboxImages, setLightboxOpen, setActiveLightboxImage, setLightboxVideo } = useLightboxState();


    const isVideo = asset.gatsbyImageData ? false : true;

    const image = !isVideo && getImage(asset.gatsbyImageData);

    const handleLightboxImage = () => {
        setActiveLightboxImage(0);
        setLightboxImages([asset]);
        setLightboxOpen(true);
    }

    const handleLightboxVideo = () => {
        setActiveLightboxImage(0);
        setLightboxVideo(asset);
        setLightboxOpen(true);
    }

    return (
        <div className="py-24 lg:py-32 overflow-hidden relative">
            <div className="max-w-5xl mx-auto w-11/12 shadow-md">
                {isVideo ? (
                    <>
                        {pushToLightbox ? (
                            <div className="relative group cursor-pointer clipped-gallery-square">
                                <img
                                    onClick={!pushToLightbox ? () => handleLightboxImage() : null}
                                    src={asset.video.thumbnailUrl} width={'100%'}
                                    height={'100%'}
                                    style={{
                                        aspectRatio: 16 / 9
                                    }}
                                />

                                <span className="block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-blue rounded-full p-3 bg-darks5 bg-opacity-50 group-hover:scale-105">
                                    <BsPlayFill className="w-8 h-8 text-white" />
                                </span>
                            </div>
                        ) : (
                            <ReactPlayer url={asset.video.mp4Url} width={'100%'} height={'100%'} controls={true} className="w-full" style={{
                                aspectRatio: 16 / 9,
                            }} />
                        )}
                    </>
                ) : (
                    <div onClick={!pushToLightbox ? () => handleLightboxImage() : null}>
                        <GatsbyImage
                            image={image}
                            width={'100%'}
                            height={'100%'}
                            className="clipped-gallery-square"
                            style={{
                                aspectRatio: 16 / 9
                            }}
                        />
                    </div>
                )}
            </div>

            <div className="absolute w-full h-full top-8 left-0 z-[-10] hidden md:block">
                <div className="relative w-full h-full z-[-10]">
                    <div className="absolute w-full top-1/2 h-16 border-t border-b hidden lg:block border-gray opacity-20" />
                </div>
            </div>
        </div>
    )
}

export default SingleAsset;