import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Controller, Thumbs } from 'swiper'

const CustomisationItem = ({ slide, index, activeIndex }) => {

    const [controlledSwiper] = useState(false)
    const [thumbsSwiper, setThumbsSwiper] = useState(null)

    return (
        <div
            className={`${activeIndex === index ? 'block' : 'hidden'}`}
            index={index}
        >
            <div className="grid grid-cols-1 md:grid-cols-18 gap-y-12">
                <div className="text-white flex flex-col flex-nowrap h-full items-start gap-y-4 md:gap-4 mt-10 lg:mt-0 mb-4 col-start-1 col-end-7">
                    {slide.title ? (
                        <h2 className="font-display text-6xl uppercase mb-4 text-darks1 whitespace-nowrap">
                            {slide.title}
                        </h2>
                    ) : null}
                    {slide.modelTitle ? (
                        <h3 className="font-display text-2xl lg:mb-6 uppercase">
                            {slide.modelTitle}
                        </h3>
                    ) : null}
                    {slide.modelBody ? (
                        <p className="font-body lg:mb-12">{slide.modelBody}</p>
                    ) : null}
                </div>

                <div className="md:col-start-9 md:col-end-18 xl:col-end-16 relative flex items-center pl-12 lg:pl-0">
                    <div className="prospector-thumbs h-auto lg:justify-center lg:items-center z-40 absolute left-0 transform lg:-translate-x-1/2">
                        <Swiper
                            modules={[Thumbs, A11y, Controller]}
                            watchSlidesProgress={true}
                            spaceBetween={10}
                            slidesPerView={slide.modelSkinVariants.length}
                            controller={{ control: controlledSwiper }}
                            direction="vertical"
                            onSwiper={setThumbsSwiper}
                            className="!overflow-visible lg:!overflow-hidden !w-[150px] !max-h-[340px] lg:!max-h-[450px] !mx-auto !ml-0 items-end"
                        >
                            {slide.modelSkinVariants.map((variant, index) => {
                                const skinImage = getImage(variant)
                                return (
                                    <SwiperSlide
                                        key={index}
                                        className="cursor-pointer"
                                    >
                                        <div className="w-max lg:float-right flex relative items-end lg:max-h-[160px]">
                                            <div className="background bg-darks4 border border-blue h-full right-0 w-full max-w-[160px] absolute bottom-0">
                                                <div className="tag h-2 w-[70%] bg-blue" />
                                            </div>
                                            <GatsbyImage
                                                image={skinImage}
                                                alt={skinImage.alt ? skinImage.alt : ''}
                                                objectFit="contain"
                                                objectPosition="bottom center"
                                                className="w-24 h-24 lg:w-32 lg:h-32 lg:-ml-2"
                                            />
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>

                    <Swiper
                        modules={[Thumbs]}
                        thumbs={{ swiper: thumbsSwiper }}
                        slidesPerView={'auto'}
                        spaceBetween={0}
                        className="prospector-slide lg:min-h-[550px] !w-full lg:!pt-6 lg:mt-0 relative !items-end flex"
                    >
                        {slide.modelSkinVariants.map((variant, index) => {
                            const mainImage = getImage(variant)
                            return (
                                <SwiperSlide
                                    key={index}
                                    className="flex justify-center"
                                >
                                    <GatsbyImage
                                        image={mainImage}
                                        alt="something"
                                        objectFit="contain"
                                        objectPosition={'bottom center'}
                                        className="max-w-[550px] lg:h-full lg:w-full lg:max-w-[650px] z-10"
                                    />
                                </SwiperSlide>
                            )
                        })}
                        <span className="absolute bottom-0 left-0 prospector-bg bg-darks3 clipped-img w-full h-full" />
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default CustomisationItem
