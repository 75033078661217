import React, { useEffect, useLayoutEffect, useRef } from 'react'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'
import cx from 'classnames'
import ReactMarkdown from 'react-markdown'
import { gsap } from 'gsap'

const ApplicationFormItem = ({ contentImage, formLink, formName, requirements, container }) => {
    const image = getImage(contentImage.gatsbyImageData)
    const bg = convertToBgImage(image)

    const wrapperRef = useRef(null)
    const textContainer = useRef(null)
    const applyContainer = useRef(null)
    const applyText = useRef(null)

    const timeline = useRef(gsap.timeline({ paused: true }))

    useEffect(() => {
        timeline.current.fromTo(wrapperRef.current, {
            scale: 1,
            duration: 0.5,
        }, {
            scale: 1.1,
            duration: 0.5,
        }, 0)
        timeline.current.fromTo(textContainer.current, {
            y: 0,
            duration: 0.5,
            opacity: 1,

        }, {
            duration: 0.5,
            y: 100,
            opacity: 0,
        }, 0)

        timeline.current.fromTo(applyContainer.current, {
            clipPath: 'polygon(0% 0, 0 0, 0 100%, 0% 100%)',
            filter: `blur(5px)`,
            duration: 0.3
        }, {
            clipPath: 'polygon(100% 0, 0 0, 0 100%, 100% 100%)',
            filter: `blur(0px)`,
            duration: 0.3
        })
    }, [])

    const onMouseEnterHandler = () => {
        timeline.current.play()
    }

    const onMouseLeaveHandler = () => {
        timeline.current.reverse()
    }

    return (
        <div ref={wrapperRef} onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler} className="w-[calc(50%-1.5rem)] md:w-[calc(33%-1.5rem)] lg:w-[calc(25%-1.5rem)] xl:w-[calc(20%-1.5rem)] cursor-pointer drop-shadow-lg h-full">
            <div className="clipped-form-link-item group-hover:scale-110 transition-all duration-500 group-hover:active">
                <a href={formLink} target="_blank">
                    <BackgroundImage tag="Div" {...bg} preserveStackingContext className="transform transition-transform duration-500 relative">
                        <div className="relative pt-48 md:pt-56 text-center overflow-hidden z-10">
                            <div ref={textContainer}>
                                <div className="bg-gray bg-opacity-50 relative w-full py-2 z-10 group-hover:opacity-0 transition-opacity duration-300">
                                    <span className="block font-display text-white uppercase text-2xl">{formName}</span>
                                </div>
                                <div className="w-full py-2 z-10 relative group-hover:opacity-0 transition-opacity duration-300">
                                    <ReactMarkdown children={requirements} className="block font-display text-white uppercase text-2xl" />
                                </div>
                            </div>
                        </div>
                        <div className="absolute bg-gray bg-opacity-50 w-full py-3 text-center  top-[45%] left-0 z-10" ref={applyContainer}>
                            <span className="block font-display text-white uppercase text-2xl" ref={applyText}>Apply</span>
                        </div>
                        <span className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-20 z-0 group-hover:bg-opacity-0 transition-all duration-500" />
                    </BackgroundImage>
                </a>
            </div>
        </div>
    )
}

export const ApplicationFormBlock = ({ block: { title, applicationForms }, container }) => {
    return (
        <div className="w-full py-32 md:pt-28">
            <div className="flex items-center justify-center flex-col lg:gap-6 max-w-screen-xl mx-auto w-11/12 z-40">
                <h2 className={cx(`font-display uppercase whitespace-pre-line tet-6xl lg:text-7xl`, {
                    'text-darks2': container === 'DatoCmsLightTextured',
                    'text-offwhite': container === 'DatoCmsDarkTextured',
                })}>{title}</h2>

                {applicationForms ? (
                    <div className="flex flex-row flex-wrap grid-cols-2 w-full gap-6 items-stretch justify-center pt-6">
                        {applicationForms.map((form) => <ApplicationFormItem {...form} />)}
                    </div>
                ) : null}
            </div>
        </div>
    )
}