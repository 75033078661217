import React from 'react'
import InnerBladeResolver from '../../InnerBladeResolve'
import { useStaticQuery, graphql } from 'gatsby'

const LightTextured = ({ blade, stack, blog, enBlog, locale, location }) => {
    const { background } = useStaticQuery(graphql`
        query getLightTexturedBackground {
            background: file(
                relativePath: { eq: "images/textures/texture-img-light.png" }
            ) {
                publicURL
            }
        }
    `)

    let notch = JSON.parse(blade.bottomNotch)
    // Check for null value before accessing array/string properties
    notch = notch && notch.length ? notch.toString().toLowerCase() : ''

    return (
        <section
            className={`-mt-8 md:-mt-6 lg:-mt-8 relative bg-top bg-repeat-all ${notch ? `notch-${notch}` : null
                } stacking-context-${stack} ${stack === 9 ? 'pt-20' : null} `}
            key={blade.id}
            style={{ backgroundImage: `url(${background?.publicURL})` }}
        >
            <InnerBladeResolver {...blade} blog={blog} enBlog={enBlog} locale={locale} location={location} />
        </section>
    )
}

export default LightTextured
