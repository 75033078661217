import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import YellowButton from '../../Buttons/YellowButton';

const ImageText = ({ block, locale }) => {
  const image = getImage(block.image);
  const direction = block.structure ? JSON.parse(block.structure)[0] : null;

  return (
    <div className="relative">
      <div className="py-24 md:pt-32 z-10 gap-y-12 md:py-28 grid grid-cols-20 auto-rows-auto lg:gap-0 lg:grid-cols-16 md:max-w-2xl lg:max-w-screen-xl mx-auto w-11/12">
        <div className={`col-start-2 col-end-20 ${block.textColour ? `text-${JSON.parse(block.textColour.toLowerCase())[0]}` : 'text-white'} ${direction === 'Left - Right' ? 'lg:col-start-10 lg:col-end-17' : 'lg:col-start-1 lg:col-end-7'}`}>
          <h2 className="font-display uppercase text-5xl lg:text-6xl mb-6">
            {block.title}
          </h2>
          <h3 className="font-display uppercase text-2xl lg:text-3xl mb-4">
            {block.subheading}
          </h3>
          <p className="font-body">{block.body}</p>
          {block.ctaText ? (
            <div className="w-48 md:w-52 mt-12">
              <YellowButton text={block.ctaText ? block.ctaText : "View"} isExternal={block.isExternalLink} link={block.internalLink ? locale.toLowerCase() !== ("en" || undefined) ? `/${locale.toLowerCase()}/${block.internalLink.pageSlug}` : `/${block.internalLink.pageSlug}` : block.externalLink} />
            </div>
          ) : null}
        </div>
        <div className={`col-start-2 col-end-20 lg:row-start-1 ${direction === 'Left - Right' ? 'lg:col-start-1 lg:col-end-9' : 'lg:col-start-9 lg:col-end-17'}`}>
          <div className="wrapper clipped-img md:h-full flex">
            {block.image ? (
              <GatsbyImage image={image} alt={block.image.alt ? block.image.alt : ''} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageText;
