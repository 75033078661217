import React, { useRef } from 'react';
import { BsFillVolumeUpFill, BsFillVolumeMuteFill, BsPauseFill, BsPlayFill } from 'react-icons/bs';
import useVideoPlayer from "../../hooks/useVideoPlayer";
import CustomPlayButton from './CustomPlayButton';

const CustomReactPlayer = ({ url, fallback }) => {
    const videoElement = useRef(null);
    const {
        playerState,
        togglePlay,
        toggleToolbar,
        handleOnTimeUpdate,
        handleVideoProgress,
        toggleMute,
    } = useVideoPlayer(videoElement);

    return (
        <div 
            className="custom-player relative flex items-center justify-center h-full w-full" 
            onMouseOver={() => toggleToolbar(true)}
            onMouseLeave={() => toggleToolbar(false)}
            onFocus={() => toggleToolbar(true)}
            onBlur={() => toggleToolbar(false)}
            role="tablist"
            tabIndex={0}
            >
            <video
                src={url}
                ref={videoElement}
                onTimeUpdate={handleOnTimeUpdate}
                className="w-full h-full object-cover"
            />
            {playerState.progress === 0 && !playerState.isPlaying ? (
                <CustomPlayButton click={togglePlay} />
            ) : null}
            {playerState.isPlaying}

            <div className={`controls absolute bottom-4 flex flex-wrap items-center justify-evenly p-3 w-full max-w-lg bg-darks5 bg-opacity-60 clipped-input transform transition-all  ${playerState.isHovered && playerState.progress > 0 ? 'translate-y-0' : 'translate-y-[200%]'}`}>
                <div className="actions flex">
                    <button onClick={togglePlay} className="text-white hover:text-blue transition-all">
                        {!playerState.isPlaying ? (
                            <BsPlayFill className="w-[30px] h-[30px]" />
                        ) : (
                            <BsPauseFill className="w-[30px] h-[30px]" />
                        )}
                    </button>
                </div>
                <input
                    type="range"
                    min="0"
                    max="100"
                    value={playerState.progress}
                    onChange={(e) => handleVideoProgress(e)}
                    className="rounded-lg bg-darks5 bg-opacity-60 h-1"
                />
                <button className="mute-btn text-white hover:text-blue transition-all" onClick={toggleMute}>
                    {!playerState.isMuted ? (
                        <BsFillVolumeUpFill className='w-[30px] h-[30px]' />
                    ) : (
                        <BsFillVolumeMuteFill className='w-[30px] h-[30px]' />
                    )}
                </button>
            </div>
        </div>
    )
}

export default CustomReactPlayer;