import { getImage, GatsbyImage } from 'gatsby-plugin-image'

import { saveAs } from 'file-saver'
import { useLightboxState } from '../../context'
import ReactPlayer from 'react-player'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { BsChevronDoubleLeft, BsChevronDoubleRight } from 'react-icons/bs'
import { MdDownload } from 'react-icons/md'
import { useWindowDimension } from '../../hooks/useWindowDimensions'

const LightboxGallery = () => {
    const {
        lightboxOpen,
        setLightboxOpen,
        activeLightboxImage,
        setActiveLightboxImage,
        lightboxImages,
        lightboxVideo,
        downloadAllowed
    } = useLightboxState()

    const [galleryPosition, setGalleryPosition] = useState(0)
    const [galleryNumber, setGalleryNumber] = useState(0)
    const [imagesVisible, setImagesVisible] = useState(3) // initially set to 3 - update on resize for MQ's
    const [play, setPlay] = useState(false);
    const [width, height] = useWindowDimension();

    useLayoutEffect(() => {
        if (width < 768) {
            setImagesVisible(3)
        } else if (width >= 768 && width < 1024) {
            setImagesVisible(6)
        } else {
            setImagesVisible(9)
        }
    }, [width, height])

    useEffect(() => {
        setGalleryNumber(lightboxImages.length)
    }, [lightboxImages])

    const handleLightBoxClose = () => {
        setPlay(false);
        setLightboxOpen(false)
    }

    const handleDownloadAsset = async (item) => {
        // download for images => url for videos
        let url = item.download ? item.download : null

        const response = await fetch(url)
        const data = await response.blob()

        return saveAs(data, item.filename)
        // return saveAs(data, `title.mp4`);
    }

    const handleActiveItemChange = (index) => {
        setActiveLightboxImage(index)
        updateImage(index)
    }

    const updateImage = () => {
        var imageData = lightboxImages?.[activeLightboxImage]?.image ? lightboxImages?.[activeLightboxImage]?.image : lightboxImages?.[activeLightboxImage]?.gatsbyImageData;
        const image = getImage(imageData);

        if (image) {
            return (
                <GatsbyImage
                    image={image}
                    src="something"
                    className="max-h-[550px]"
                    objectFit="contain"
                    objectPosition="center"
                />
            )
        } else if (lightboxVideo) {
            const videoUrl = lightboxVideo?.url ? lightboxVideo.url : lightboxImages[activeLightboxImage]?.video?.mp4Url;

            return (
                <ReactPlayer
                    url={videoUrl}
                    controls={true}
                    width={'100%'}
                    height={'100%'}
                    className="w-full h-full"
                    playing={play}
                    onPlay={() => setPlay(true)}
                    onPause={() => setPlay(false)}
                />
            )
        }
    }

    const nextImage = () => {
        handleActiveItemChange(activeLightboxImage + 1)
    }

    const prevImage = () => {
        handleActiveItemChange(activeLightboxImage - 1)
    }

    const galleryNext = () => {
        setGalleryPosition(galleryPosition + 1)
    }

    const galleryPrev = () => {
        setGalleryPosition(galleryPosition - 1)
    }

    return (
        <div
            className={`fixed w-full h-full z-50 bg-darks5 bg-opacity-95 top-0 left-0 transition-all duration-500 ${lightboxOpen
                ? 'opacity-100 visible pointer-events-auto'
                : 'opacity-0 invisible pointer-events-none'
                }`}
        >
            <div className="absolute z-40 top-0 left-0 w-full flex items-center justify-end gap-6 pt-4 pr-4">
                <span className="">
                    {downloadAllowed && (
                        <MdDownload
                            className="text-3xl text-white hover:text-orange cursor-pointer"
                            onClick={() =>
                                handleDownloadAsset(
                                    lightboxImages[activeLightboxImage]
                                )
                            }
                            role="button"
                            tabIndex={0}
                        />
                    )}
                </span>
                <span
                    className="cursor-pointer text-white text-3xl hover:text-orange"
                    onClick={handleLightBoxClose}
                    onKeyPress={(e) => (e.key === "Enter") && handleLightBoxClose()}
                    role="button"
                    tabIndex={0}
                >
                    &#x2715;
                </span>
            </div>
            <div className="relative w-full h-full flex items-center justify-center flex-col">
                <div className="modal w-[90%] h-[80%] max-h-[720px] max-w-[1280px] flex flex-col lg:flex-row lg:flex-nowrap items-center justify-center">
                    <span className="w-20 hidden lg:block">
                        {activeLightboxImage > 0 ? (
                            <BsChevronDoubleLeft
                                className="text-3xl text-white hover:text-blue cursor-pointer mx-auto"
                                onClick={prevImage}
                            />
                        ) : null}
                    </span>
                    <div className="image w-full h-full max-w-[1280px] flex items-center justify-center">
                        {updateImage()}
                    </div>

                    <span className="w-20 hidden lg:block">
                        {lightboxImages.length - 1 > activeLightboxImage ? (
                            <BsChevronDoubleRight
                                className="text-3xl text-white hover:text-blue cursor-pointer mx-auto"
                                onClick={nextImage}
                            />
                        ) : null}
                    </span>

                    <div className="w-60 order-2 lg:hidden flex items-center justify-between mt-8">
                        <span className="block w-20">
                            {activeLightboxImage > 0 ? (
                                <BsChevronDoubleLeft
                                    className="text-3xl text-white hover:text-blue cursor-pointer mx-auto"
                                    onClick={prevImage}
                                />
                            ) : null}
                        </span>
                        <span className="block w-20">

                            {lightboxImages.length - 1 > activeLightboxImage ? (
                                <BsChevronDoubleRight
                                    className="text-3xl text-white hover:text-blue cursor-pointer mx-auto"
                                    onClick={nextImage}
                                />
                            ) : null}
                        </span>
                    </div>
                </div>

                {lightboxImages?.length > 0 ? (
                    <div
                        className="w-11/12 h-[150px] flex flex-row flex-nowrap gap-8 mt-12 items-center justify-center"
                        role="tablist"
                        tabIndex={0}
                    >
                        <span className="block w-20">
                            {galleryPosition !== 0 ? (
                                <BsChevronDoubleLeft
                                    className="text-3xl text-white hover:text-blue cursor-pointer"
                                    onClick={galleryPrev}
                                    onKeyPress={(e) => (e.key === "Enter") && galleryPrev}
                                    role="tab"
                                    tabIndex={0}
                                />
                            ) : null}
                        </span>
                        {lightboxImages.map((image, index) => {
                            var imageData = image.image ? image.image : image.gatsbyImageData;
                            const lbImage = getImage(imageData);

                            return (
                                <div
                                    className={`max-w-[100px] cursor-pointer hover:transform hover:scale-110 ${index >= galleryPosition &&
                                        index <= galleryPosition + imagesVisible
                                        ? 'block'
                                        : 'hidden'
                                        } ${activeLightboxImage === index
                                            ? 'opacity-100 transform scale-110'
                                            : 'opacity-50'
                                        }`}
                                    onClick={() =>
                                        handleActiveItemChange(index)
                                    }
                                    onKeyPress={(e) => (e.key === "Enter") && handleActiveItemChange(index)}
                                    role="tab"
                                    tabIndex={0}
                                    key={index}
                                >
                                    {lbImage ? (
                                        <GatsbyImage
                                            image={lbImage}
                                            alt="someImage"
                                            objectFit="contain"
                                            objectPosition="cover"
                                        />
                                    ) : (
                                        <img
                                            src={image.video.thumbnailUrl}
                                            alt="something"
                                        />
                                    )}
                                </div>
                            )
                        })}
                        <span className="block w-20">
                            {galleryNumber - galleryPosition > imagesVisible ? (
                                <BsChevronDoubleRight
                                    className="text-3xl text-white hover:text-blue cursor-pointer"
                                    onClick={galleryNext}
                                    onKeyPress={(e) => (e.key === "Enter") && galleryNext}
                                    role="tab"
                                    tabIndex={0}
                                />
                            ) : null}
                        </span>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default LightboxGallery
