import React from 'react'
import useMouse from '@react-hook/mouse-position'
import Cursor from '../../../assets/svgs/cursor-prod.svg'

const TestCursor = ({ children, className }) => {
    const ref = React.useRef(null)
    const { x, y, pageX, pageY } = useMouse(ref, {
        enterDelay: 100,
        leaveDelay: 100,
    })

    return (
        <div ref={ref} className={`cursor-none ${className}`}>
            {children}
            {pageX && pageY ? (
                <Cursor
                    className="absolute top-0 left-0 pointer-events-none z-50 max-w-[75px] transform -translate-x-1/2 -translate-y-1/2 transition duration-75 lg:block hidden"
                    style={{ left: `${x}px`, top: `${y}px` }}
                />
            ) : null}
        </div>
    )
}

export default TestCursor