export const chunkArray = (array, chunk_size) => {
    let index = 0;
    let arrLength = array.length;
    let tempArr = [];

    for (index = 0; index < arrLength; index += chunk_size) {
        let myChunk = array.slice(index, index + chunk_size);
        tempArr.push(myChunk);
    }

    return tempArr;
}